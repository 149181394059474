import React, { useState, useEffect } from "react";
import { Layout, Menu, Pagination } from "antd";
import { DatePicker, Space } from "antd";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { Table, Breadcrumb, Modal } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  getReportListAction,
  blockUserAction,
  getDeleteListAction,
} from "../../redux/Action/Auth";
import appconstant from "../../themes/appconstant";

const { RangePicker } = DatePicker;
const { Header, Content, Footer, Sider } = Layout;

const ReportManagement = () => {
  const dispatch = useDispatch();
  const naviagte = useNavigate();
  const reportListData = useSelector(
    (state) => state.userManagementReducer.reportList.list
  );
  const blockSuccess = useSelector(
    (state) => state.userManagementReducer.isBlockSuccess
  );
  const deleteSuccess = useSelector(
    (state) => state.deleteManagementReducer.deleteSuccess
  );

  const paginationData = useSelector(
    (state) => state.userManagementReducer.reportList.pagination
  );
  const loading = useSelector((state) => state.userManagementReducer.isLoading);

  const [payload, setPayload] = useState({
    offset: 0,
    limit: 10,
    sort: null,
    order: null,
    search: "",
  });
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    if (blockSuccess == true || deleteSuccess == true) {
      dispatch(
        getReportListAction({ ...payload, search: searchValue, offset: 0 })
      );
    }
  }, [blockSuccess, deleteSuccess]);

  useEffect(() => {
    dispatch(
      getReportListAction({ ...payload, search: searchValue, offset: 0 })
    );
  }, [searchValue]);

  const handleSearch = (e) => {
    const searchData = e?.target?.value;
    if (!searchData?.startsWith(" ")) setSearchValue(searchData);
  };

  const handleChange = (col, page) => {
    const tempPayload = { ...payload };

    if (col && col.columnKey) {
      tempPayload["sort"] = col?.columnKey || payload?.sort;
      tempPayload["order"] =
        col?.order === "ascend"
          ? 1
          : col?.order === "descend"
          ? -1
          : payload?.order;
    } else if (page) {
      tempPayload["offset"] = (page - 1) * 10;
    }
    setPayload(tempPayload);
    dispatch(getReportListAction({ ...tempPayload }));
  };

  const blockModal = (currentUser) => {
    Modal.confirm({
      title: currentUser.isBlock ? "Unblock" : "Block",
      content: currentUser.isBlock
        ? "Are you sure, you want to unblock this user?"
        : "Are you sure, you want to block this user?",
      okText: "Yes",
      centered: true,
      cancelText: "No",
      onOk() {
        const blockData = {
          user: currentUser.reportedUserID,
        };
        dispatch(blockUserAction(blockData));
      },
      onCancel() {},
      maskClosable: true,
      className: "new-button",
    });
  };

  const deleteModel = (id) => {
    Modal.confirm({
      title: "Delete",
      content: (
        <div className="coins-input ">
          <p style={{ marginTop: "20px" }}>
            {" "}
            Are you sure, you want to delete this user?
          </p>
        </div>
      ),
      okText: "Yes ",
      centered: true,
      cancelText: "No ",
      onOk() {
        const data = {
          user: id,
        };

        dispatch(getDeleteListAction(data));
        dispatch(
          getReportListAction({ ...payload, search: searchValue, offset: 0 })
        );
      },
      onCancel() {},
      maskClosable: true,
      className: "new-button",
    });
  };

  const handleBtn = (data) => {
    if (data?.feedback) {
      naviagte("/reportdetails", {
        state: {
          reportid: data.reportedById,
          listid: data._id,
        },
      });

    } else {
      naviagte("/reportdetails", {
        state: {
          reportid: data.reportedUserID,
          listid: data._id,
        },
      });
    }
  };

  const dataSource = reportListData?.map((e, index) => ({
    key: index + 1,
    _id: e?._id,
    name: `${e?.reportedUser?.firstName} ${e?.reportedUser?.lastName || "N/A"}`,
    email: e?.reportedUser?.email || "N/A",
    reportBy: `${e?.reportBy?.firstName || "N/A"} ${
      e?.reportBy?.lastName || "N/A"
    }`,
    reportedById : e?.reportBy?._id,
    isBlock: e?.reportedUser?.isBlock,
    reportedUserID: e?.reportedUser?._id,
    seen: e?.seen,
    feedback: e?.feedback,
  }));

  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "srno",
      key: "srno",
      sorter: false,
      render: (value, item, index) => payload.offset + (index + 1),
    },
    {
      title: "Full Name",
      dataIndex: "name",
      key: "firstName",
      sorter: true,
      render: (text, record) => {
        // Check if feedback is true, show "App Report", otherwise show the name
        return record.feedback ? "App Report" : record.name;
      },
    },

    {
      title: "Email Address",
      dataIndex: "email",
      key: "email",
      sorter: true,
    },
    {
      title: "Reported By",
      dataIndex: "reportBy",
      key: "reportBy",
      sorter: true,
    },
    {
      title: "Reported Type",
      dataIndex: "reportBy",
      key: "reportBy",
     
      render: (text, record) => {
        // Check if feedback is true, show "App Report", otherwise show the name
        return record.feedback ? "App Report" : "User Report";
      },
    },
    {
      title: "Actions",
      dataIndex: "actions",
      key: "actions",
      render: (title, data, index) => (
        <div>
          {/* <Link to="/reportdetails" state={data.reportedUserID}> */}
          <button
            className="view-btn"
            type="submit"
            onClick={() => handleBtn(data)}
          >
            {data.seen ? "viewed" : appconstant.view}
          </button>
          {/* </Link> */}
          <button
            className="block-btn"
            type="submit"
            onClick={() => blockModal(data)}
          >
            {data?.isBlock ? appconstant.unBlock : appconstant.block}
          </button>
          <button
            className="delete-btn"
            type="submit"
            onClick={() => deleteModel(data?.reportedUserID)}
          >
            {appconstant.delete}
          </button>
        </div>
      ),
    },
  ];

  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };

  const [isVisible, setVisible] = useState(false);
  const handleClick = () => {
    setVisible(!isVisible);
  };
  useEffect(() => {
    document.title = "YezidiLink";
  }, []);

  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/dashboard">
            <FontAwesomeIcon icon={faHome} />
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{appconstant.management}</Breadcrumb.Item>
      </Breadcrumb>

      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <div className="content-e">
          <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 system-line">
            <h6 className="text-white text-capitalize ps-3">
              {appconstant.management}
            </h6>
            <div className="search-button">
              <input
                type="text"
                onChange={handleSearch}
                className="icon-search search-bar"
                placeholder="Search"
                value={searchValue}
              />
            </div>
          </div>

          <Table
            className="user-manage-table"
            dataSource={dataSource}
            columns={columns}
            showSorterTooltip={false}
            loading={loading}
            pagination={false}
            onChange={(x, y, col) => handleChange(col)}
          />
          <Pagination
            showTitle={false}
            className="pagination-wrapper"
            total={paginationData?.totalCount}
            current={paginationData?.currentPage}
            onChange={(page) => handleChange(null, page)}
          />
        </div>
      </div>
    </div>
  );
};
export default ReportManagement;
