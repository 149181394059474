import React, { useState, useEffect } from "react";
import images from '../../themes/appImage';
import {
    BrowserRouter as Router,
    Link,
    useNavigate
} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Forgot } from '../../redux/Action/Auth';
import { useDispatch, useSelector } from 'react-redux';



const ForgotPassword = () => {
    const dispatch = useDispatch();

    const forgotLoader = useSelector(state => state.authReducer.isLoading);

   
    useEffect(() => {
        document.title = 'YezidiLink';
        window.scrollTo(0, 0);
    }, []);

    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .required("Please enter email address.")
            .matches(
                /^[a-zA-Z0-9]+(\.[a-zA-Z0-9]+)*@[a-zA-Z0-9]+(\.[a-zA-z]{2,10})+$/,
                "Please enter a valid email address."
            )
            .max(255),
    });

    const handleSubmit = (values, { setSubmitting }) => {
        dispatch(Forgot(values));
    };

    const formik = useFormik({
        initialValues: {
            email: "",
        },
        validationSchema: validationSchema,
        onSubmit: handleSubmit
    });

    return (
        <div className="image-bg">
            <div className="page-header">
                <div className="main-div">
                    <div className="login-form ">
                        <div className="back">
                            <Link to="/login">
                                <button type="submit" className="btn2">
                                    <FontAwesomeIcon className="icon-angle-left" icon={faAngleLeft} />
                                </button>
                            </Link>
                        </div>
                        <h2 style={{ marginTop: "20px" }}>Forgot Password</h2>
                        <div>
                            <img src={images.forgotim} alt="Forgot Password" />
                        </div>
                        <form onSubmit={formik.handleSubmit}> {/* Wrap your input fields and button inside a form */}
                            <div className="user">
                                <input
                                    className="email-input"
                                    type="text"
                                    placeholder="Email Address"
                                    value={formik.values.email}
                                    onChange={(e) => {
                                        let data = e.target.value.trim();
                                        formik.setFieldValue("email", data);
                                    }}
                                />
                                {formik.errors.email && formik.touched.email ? (
                                    <div className="error" style={{ color: "red", display: "flex",marginLeft:0, }}>{formik.errors.email}</div>
                                ) : null}
                            </div>

                            <div className="login-link" style={{ justifyContent: 'center !important' }}>
                                <button className="btn-forget" type="submit" style={{ border: "none" }} disabled = {forgotLoader}>
                                    <div>Send</div>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ForgotPassword;
