import { reduxConstant } from "../../constants/appconstant";

const initialState = {
  isLoading: false,
  gameList: [],
  pagination: {},
};

export const gameManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    case reduxConstant.API_GET_GAME_LIST_LOAD:
      return { ...state, isLoading: true };
    case reduxConstant.API_GET_GAME_LIST_SUCCESS:
      return {
        ...state,
        isLoading: false,
        gameList: action?.result?.data,
        pagination: action?.result?.pagination,
      };

    //default
    default:
      return { ...state };
  }
};
