import { msgConstant, reduxConstant } from "../../constants/appconstant";
import { put, call, takeLatest } from "@redux-saga/core/effects";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { getGameList } from "../Api/index";
import { navigate } from "../../utils/navigator";

function* getGameListSaga(action) {
  try {
    const response = yield call(getGameList, action.data);
    if (response?.status >= 0 && response?.status < 1) {
      yield put({
        type: reduxConstant.API_GET_GAME_LIST_SUCCESS,
        result: response?.result,
      });
    } else {
      toast.error(response.result.message);
      yield put({ type: reduxConstant.API_GET_GAME_LIST_FAIL });
    }
  } catch (error) {
    toast.error(msgConstant.messageStatus500);
    yield put({ type: reduxConstant.API_GET_GAME_LIST_FAIL });
  }
}

export default function* GameManagementSaga() {
  yield takeLatest(reduxConstant.API_GET_GAME_LIST_LOAD, getGameListSaga);
}
