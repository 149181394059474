import React, { useState, useEffect } from "react";
import { Layout, Menu , Pagination} from "antd";
import images from "../../themes/appImage";
import { DatePicker, Space } from "antd";

import { BrowserRouter as Router, Link } from "react-router-dom";
import HeaderAdmin from "../../layout/header";

import Sidebar from "../../layout/sidebar";
import { FontAwesomeIcon, fadashcube } from "@fortawesome/react-fontawesome";
import {
  faHome,
  faUser,
  faNewspaper,
  faBell,
  faShoppingCart,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { Table, Breadcrumb, Modal } from "antd";
import MobileSidebar from "../../layout/mobilesidebar";
import appconstant from "../../themes/appconstant";
import { typeList } from "antd/lib/message";
import { useDispatch, useSelector } from "react-redux";
import { getDeleteListApi } from "../../redux/Api";
import {
  getContactListAction,
  getDeleteListAction,
  getDeleteUserAction,
  userListAction,
} from "../../redux/Action/Auth";

const { RangePicker } = DatePicker;
const { Header, Content, Footer, Sider } = Layout;

const Deleteuser = () => {
  const dispatch = useDispatch();
  const userListData = useSelector(
    (state) => state.userManagementReducer.userList
  );

  const paginationData = useSelector(
    (state) => state.userManagementReducer.pagination
  );
  const loading = useSelector((state) => state.userManagementReducer.isLoading);

  const [payload, setPayload] = useState({
    offset: 0,
    limit: 10,
    sort: null,
    order: null,
    search: "",
    type: "deleteUser",
  });
  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    dispatch(userListAction({ ...payload, search: searchValue, offset: 0 }));
  }, [searchValue]);

  const handleSearch = (e) => {
    const serachData = e?.target?.value;
    if (!serachData?.startsWith(" ")) setSearchValue(serachData);
  };

  const handleChange = (col, page) => {
    const tempPayload = { ...payload };

    if (col && col.columnKey) {
      tempPayload["sort"] = col?.columnKey || payload?.sort;
      tempPayload["order"] =
        col?.order === "ascend"
          ? 1
          : col?.order === "descend"
          ? -1
          : payload?.order;
    } else if (page) {
      tempPayload["offset"] = (page - 1) * 10;
    }
    setPayload(tempPayload);
    dispatch(userListAction({ ...tempPayload }));
  };

  const dataSource = userListData?.map((e) => {
    console.log(e, "asdjkhas");
    return {
      _id: e?._id,
      email: e?.email,
      firstName: e?.firstName,
      lastName: e?.lastName,
      phone: e?.phone,
      countryCode: e?.countryCode,
      reason: e?.deleteReason,
    };
  });

  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "srno",
      key: "srno",
      sorter: false,
      render: (value, item, index) => payload.offset + (index + 1),
    },
    {
      title: "Full Name",
      dataIndex: "name",
      key: "firstName",
      sorter: true,
      render: (title, data, index) => (
        <>
          {data?.firstName || "N/A"} {data?.lastName || "N/A"}
        </>
      ),
    },
    {
      title: "Email Address",
      dataIndex: "email",
      key: "email",
      sorter: true,
      render: (title, data, index) => (
        <>
          {data?.email || "N/A"}
        </>
      ),
    },
    {
      title: "Phone Number",
      dataIndex: "phone",
      key: "phone",
      sorter: true,
      render: (title, data, index) => {
        const phoneNumber = data?.countryCode ? `${data.countryCode} ${data.phone}` : data?.phone || "N/A";
        return phoneNumber;
      },
    },
    {
      title: "Reason",
      dataIndex: "reason",
      key: "reason",
      sorter: true,
      render: (title, data, index) => (
        <>
          {data?.reason || "N/A"}
        </>
      ),
    },
    {
      title: "Action",
      dataIndex: "Posted",
      key: "Posted",
      render: (title, data, index) => (
        <div>
          <Link to="/deleteuserdetails" state={data._id}>
            <button className="view-btn" type="submit">
              {appconstant.view}
            </button>
          </Link>
          <button
            className="add-coins"
            type="submit"
            onClick={() => deleteModel(data?._id)}
          >
            {appconstant.delete}
          </button>
        </div>
      ),
    },
  ];

  const deleteModel = (id) => {
    Modal.confirm({
      title: "Delete",
      content: (
        <div className="coins-input ">
          <p style={{ marginTop: "20px" }}>
            {" "}
            Are you sure, you want to delete this user?
          </p>
        </div>
      ),
      okText: "Yes ",
      centered: true,
      cancelText: "No ",
      onOk() {
        const data = {
          user: id,
        };

        dispatch(getDeleteUserAction(data));
        dispatch(
          userListAction({ ...payload, search: searchValue, offset: 0 })
        );
      },
      onCancel() {},
      maskClosable: true,
      className: "new-button",
    });
  };
  

  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };

  const [isVisible, setVisible] = useState(false);
  const handlewClick = () => {
    setVisible(!isVisible);
  };
  useEffect(() => {
    document.title = "YezidiLink";
  }, []);

  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/dashboard">
            <FontAwesomeIcon icon={faHome} />
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{appconstant.deleteuser}</Breadcrumb.Item>
      </Breadcrumb>

      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <div className="content-e">
          <div class="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 system-line">
            <h6 class="text-white text-capitalize ps-3">
              {appconstant.deleteuser}
            </h6>
            <div className="search-button">
              <input
                type="text"
                onChange={handleSearch}
                className="iocn-search  search-bar"
                placeholder="Search"
                value={searchValue}
              />
            </div>
          </div>

          <Table
            className="unser-manage-table"
            dataSource={dataSource}
            columns={columns}
            showSorterTooltip={false}
            loading={loading}
            pagination={false}
            onChange={(x, y, col) => handleChange(col)}
          />
          <Pagination
            showTitle={false}
            className="pagination-wrapper"
            total={paginationData?.totalCount}
            current={paginationData?.currentPage}
            onChange={(page) => handleChange(null, page)}
          />
        </div>
      </div>
    </div>
  );
};
export default Deleteuser;
