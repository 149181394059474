import React, { useState, useEffect } from "react";
import { Layout, Menu, Table, Breadcrumb, Modal, Pagination } from 'antd';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { BrowserRouter as Router, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getGlobalListAction } from "../../redux/Action/Auth";
import appconstant from "../../themes/appconstant";

const { Header, Content, Footer, Sider } = Layout;

const GlobalManagement = () => {
    const dispatch = useDispatch();
    const globaListData = useSelector(state => state.globalmanagementReducer.globalList.list);
    const paginationData = useSelector(state => state.globalmanagementReducer.globalList.pagination);
    const loading = useSelector(state => state.globalmanagementReducer.isLoading);
    const globaList = useSelector(state => state.globalmanagementReducer)
    console.log("global",globaList);

    const [payload, setPayload] = useState({
        offset: 0,
        limit: 10,
        sort: null,
        order: null,
        search: "",
    });

    const [searchValue, setSearchValue] = useState("");

    useEffect(() => {
        dispatch(getGlobalListAction({ ...payload, search: searchValue, offset: 0 }));
    }, [searchValue]);

    const handleSearch = (e) => {
        const searchData = e?.target?.value;
        if (!searchData?.startsWith(" ")) setSearchValue(searchData);
    };

    const handleChange = (col, page) => {
        const tempPayload = { ...payload };
        if (col && col.columnKey) {
            tempPayload["sort"] = col?.columnKey || payload?.sort;
            tempPayload["order"] = col?.order === "ascend" ? 1 : col?.order === "descend" ? -1 : payload?.order;
        } else if (page) {
            tempPayload["offset"] = (page - 1) * 10;
        }
        setPayload(tempPayload);
        dispatch(getGlobalListAction({ ...tempPayload }));
    };
    const formatDate = (dateString) => {
        const date = new Date(dateString);
        const day = date.getDate().toString().padStart(2, '0');
        const month = (date.getMonth() + 1).toString().padStart(2, '0'); 
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
      }

    const dataSource = globaListData?.map((item, index) => ({
  
        key: index + 1,
        Heading: item.heading,
        Details: item.details,
        date: formatDate(item.date),
    }));

    const columns = [
        {
            title: 'Sr. No.',
            dataIndex: 'key',
            key: 'srno',
            render: (value, item, index) => payload.offset + (index + 1),
        },
        {
            title: 'Heading',
            dataIndex: 'Heading',
            key: 'heading',
            sorter: true,
        },
        {
            title: 'Details',
            dataIndex: 'Details',
            key: 'details',
            sorter: true,
        },
        {
            title: 'Date',
            dataIndex: 'date',
            key: 'date',
            sorter: true,
        },
    ];

    return (
        <div>
            <Breadcrumb>
                <Breadcrumb.Item><Link to="/dashboard"><FontAwesomeIcon icon={faHome} /></Link></Breadcrumb.Item>
                <Breadcrumb.Item>{appconstant.globalmanage}</Breadcrumb.Item>
            </Breadcrumb>
            <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                <div className="content-e">
                    <div style={{ paddingTop: '12px' }}>
                        <Link to="/createmessage">
                            <button type="submit" className="button-list">{appconstant.create}</button>
                        </Link>
                    </div>
                    <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 system-line">
                        <h6 className="text-white text-capitalize ps-3">{appconstant.pastannouncement}</h6>
                        <input
                            type="text"
                            onChange={handleSearch}
                            className="iocn-search search-bar"
                            placeholder="Search"
                            value={searchValue}
                        />
                    </div>
                    <Table
                        dataSource={dataSource}
                        columns={columns}
                        showSorterTooltip={false}
                        loading={loading}
                        pagination={false}
                        onChange={(pagination, filters, sorter) => handleChange(sorter)}
                    />
                    <Pagination
                        showTitle={false}
                        className="pagination-wrapper"
                        total={paginationData?.totalCount}
                        current={paginationData?.currentPage}
                        onChange={(page) => handleChange(null, page)}
                    />
                </div>
            </div>
        </div>
    );
};

export default GlobalManagement;
