import { APIMethod } from "./methods";

const headers = {
  "access-control-allow-origin": "*",
  "Content-Type": "application/json",
  Accept: "application/json"
};

const headers1 = {
    "Content-Type": "multipart/form-data; boundary=12345",
    Accept: "application/json",
}

//***********************************************  AUTH ***************************************************************/

//login
export const LoginApi = (data) => {
  return APIMethod.POST("admin/login", JSON.stringify(data), headers);
};

export const forgotApi = (data) => {
  return APIMethod.POST("admin/forgotPassword", JSON.stringify(data), headers);
};

export const dashboardApi = () => {
  return APIMethod.GET("admin/dashboard", null, headers);
};

export const logoutApi = () => {
  return APIMethod.GET("admin/logout", null, headers);
};

export const changePasswordApi = (data) => {
  return APIMethod.POST("admin/changePassword", data, headers);
};

export const linkValidApi = (data) => {
  return APIMethod.POST("admin/resetPasswordLink", data, headers);
};

export const resetPasswordApi = (data) => {
  return APIMethod.POST("admin/resetPassword", data, headers);
};

export const sendNotificationApi = (data) => {
  return APIMethod.POST("admin/notification", data, headers);
};

export const userListApi = (data) => {
  return APIMethod.POST("admin/userList", data, headers);
};

export const blockUserApi = (data) => {
  return APIMethod.POST(`admin/blockUser`, data ,headers);
};

export const viewUserApi = (data) => {
  return APIMethod.POST(`admin/userDetails`, data , headers);
};

export const editUserApi = (data) => {
  return APIMethod.POST("admin/editUser", data, headers1);
};

export const shopListApi = (data) => {
  return APIMethod.POST("admin/shop-list", data, headers);
};

export const viewShopApi = (data) => {
  return APIMethod.GET(`admin/shop-view/${data}`, headers);
};

export const addItemApi = (data) => {
  return APIMethod.GET("admin/add-shop-item", headers);
};

export const notificationList = (data) => {
  return APIMethod.POST("admin/notification-list", data, headers);
};

export const notificationEdit = (data) => {
  return APIMethod.PUT("admin/notification-edit", data, headers);
};

export const notificationDelete = (data) => {
  return APIMethod.DELETE("admin/notification-delete", data, headers);
};

export const addTournament = (data) =>
  APIMethod.POST("admin/add-tournament", data, headers);

export const tournamentList = (data) =>
  APIMethod.POST("admin/tournament-admin-list", data, headers);

export const tournamentDetails = (data) =>
  APIMethod.POST("admin/tournament-detail", data, headers);

export const tournamentBlock = (data) =>
  APIMethod.GET(`admin/tournament-block/${data.id}`, headers);

export const tournamentEdit = (data) =>
  APIMethod.PUT(`admin/tournament-edit`, data, headers);

export const addShopDataApi = (data) =>
  APIMethod.POST(`admin/shop-add`, data, headers);

export const getShopDataApi = (data) =>
  APIMethod.POST(`admin/shop-list`, data, headers);

export const userAddCoinApi = (data) =>
  APIMethod.PUT("admin/add-coins", data, headers);

export const getGameList = (data) =>
  APIMethod.POST("admin/game-list", data, headers);

export const shopEditApi = (data) =>
  APIMethod.PUT("admin/shop-edit ", data, headers);

export const getContactListApi = (data) => APIMethod.POST("admin/listContactUs",data ,headers);

export const getDeleteListApi = (data) => APIMethod.POST("admin/deleteUser",data ,headers);
export const getDeleteUserApi = (data) => APIMethod.POST("admin/deleteUserPermanent",data ,headers);


export const createAnnoucmentApi = (data) => APIMethod.POST("admin/globalAnnouncement", data ,headers);

export const getGlobalListApi = (data) => APIMethod.POST("admin/announcementList" ,data,headers);

export const getReportListApi = (data) => APIMethod.POST("admin/reportList", data , headers);

export const idVerificationApi = (data) => APIMethod.POST("admin/userIdVerification" ,data, headers);

export const deleteImgApi = (data) => APIMethod.POST("admin/removeGallery" ,data, headers);


