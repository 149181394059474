import React, { useEffect, useState } from "react";
import { Layout, Input } from "antd";
import images from "../../themes/appImage";
import { Link, useNavigate } from "react-router-dom";
import appconstant from "../../themes/appconstant";
import { loginAction } from "../../redux/Action/Auth";
import { useDispatch } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";


const Login = () => {
  const dispatch = useDispatch();


  useEffect(() => {
    document.title = "YezidiLink";
  }, []);

  const handleInputChange = (e) => {

    // if (e.target.name == "email") {
    //   e.target.value = e.target.value.replace(/\s/g, "").toLowerCase();
    // }
    // else {
      e.target.value = e.target.value.replace(/\s/g, "")
    // }
  };



  return (
    <div className="image-bg">
      <div className="page-header">
        <div className="main-div">
          <div className="login-form">
            <img style={{ height: "150px" }} src={images.logo} alt="Logo" />
            <h3>Admin Login</h3>
            <Formik
              initialValues={{
                email: "",
                password: "",
                deviceType: "web",
                deviceToken: Date.now(),
              }}
              validationSchema={Yup.object({
                email: Yup.string()
                  .required("Please enter email address.")
                  .matches(
                    /^[a-zA-Z0-9]+(\.[a-zA-Z0-9]+)*@[a-zA-Z0-9]+(\.[a-zA-Z]{2,10})+$/,
                    "Please enter valid email address."
                  )
                  .max(255),
                password: Yup.string()
                  .required("Please enter password.")
                  .matches(/^(\S+$)/, "Please enter valid password."),
              })}
              onSubmit={(values, { setSubmitting }) => {
                console.log("hello")
                dispatch(loginAction(values));

                setSubmitting(false);
              }}
            >
              <Form noValidate className="logCheck">
                <div className="user">
                  <div className="lucas-input ">
                    <Field
                      className="email-input"
                      type="text"
                      name="email"
                      placeholder="Email Address"
                      onInput={handleInputChange}
                      as={Input}
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="error"
                      style={{
                        color: "red",
                        display: "flex",
                        marginLeft: "0px",
                      }}
                    />
                  </div>
                  <div className="lucas-input">
                    <div className="password-field">
                      <Field
                        name="password"
                        placeholder="Password"
                        onInput={handleInputChange}
                        as={Input.Password}
                      />
                    </div>
                    <ErrorMessage
                      name="password"
                      component="div"
                      className="error"
                      style={{
                        color: "red",
                        display: "flex",
                        marginLeft: "0px",
                      }}
                    />
                  </div>
                </div>
                <button
                  className="btns-app"
                  type="submit"

                >
                  <div className="btns-image">
                    <div className="btns-link">
                      <div className="btn-txt">{appconstant.login}</div>
                    </div>
                  </div>
                </button>
              </Form>
            </Formik>
            <div className="forgot-link">
              <Link to="/forgotpassword">Forgot Password?</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
