import React, { useState, useEffect } from "react";
import { Pagination } from "antd";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { Table, Breadcrumb, Modal } from "antd";
import appconstant from "../../themes/appconstant";
import { useDispatch, useSelector } from "react-redux";
import { userListAction } from "../../redux/Action/Auth";


const UserSigned = () => {
  const dispatch = useDispatch();
  const userListData = useSelector(
    (state) => state.userManagementReducer.userList
  );

  const paginationData = useSelector(
    (state) => state.userManagementReducer.pagination
  );
  const loading = useSelector((state) => state.userManagementReducer.isLoading);

  const [payload, setPayload] = useState({
    offset: 0,
    limit: 10,
    sort: null,
    order: null,
    search: "",
    type: "signUp",
  });

  const [searchValue, setSearchValue] = useState("");

  useEffect(() => {
    dispatch(userListAction({ ...payload, search: searchValue, offset: 0 }));
  }, [searchValue]);

  const handleSearch = (e) => {
    const serachData = e?.target?.value;
    if (!serachData?.startsWith(" ")) setSearchValue(serachData);
  };

  const handleChange = (col, page) => {
    const tempPayload = { ...payload };
    if (col && col.columnKey) {
      tempPayload["sort"] = col?.columnKey || payload?.sort;
      tempPayload["order"] =
        col?.order === "ascend"
          ? 1
          : col?.order === "descend"
            ? -1
            : payload?.order;
    } else if (page) {
      tempPayload["offset"] = (page - 1) * 10;
    }
    setPayload(tempPayload);
    dispatch(userListAction({ ...tempPayload }));
  };

  const dataSource = userListData?.map((e) => {
    return {
      _id: e?._id,
      email: e?.email,
      firstName: e?.firstName,
      lastName: e?.lastName,
      phone: e?.phone,
      gender: e?.gender,
      countryCode: e?.countryCode,
    };
  });

  useEffect(() => {
    return () => Modal.destroyAll();
  }, []);

  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "srno",
      key: "srno",
      sorter: false,
      render: (value, item, index) => payload.offset + (index + 1),
    },
    {
      title: "Full Name",
      dataIndex: "name",
      key: "firstName",
      sorter: true,
      render: (title, data, index) => (
        <>
          {data?.firstName || "N/A"} {data?.lastName || "N/A"}
        </>
      ),
    },
    {
      title: "Email Address",
      dataIndex: "email",
      key: "email",
      sorter: true,
      render: (title, data, index) => <>{data?.email || "N/A"}</>,
    },
    {
      title: "Gender",
      dataIndex: "gender",
      key: "gender",
      sorter: true,
      render: (title, data, index) => <>{data?.gender || "N/A"}</>,
    },
    {
      title: "Phone Number",
      dataIndex: "phone",
      key: "phone",
      sorter: true,
      render: (title, data, index) => {
        const phoneNumber = data?.countryCode
          ? `${data.countryCode} ${data.phone}`
          : data?.phone || "N/A";
        return phoneNumber;
      },
    },
    {
      title: "Action",
      dataIndex: "Posted",
      key: "Posted",
      render: (title, data, index) => {
        return (
          <div>
            <Link to="/signUpUser"
              state={{
                id: data?._id,
                from: "User Signed",
                fromRoute: "usersigned"
              }}
            >
              <button className="view-btn" type="submit">
                {appconstant.view}
              </button>
            </Link>
          </div>
        );
      },
    },
  ];

  useEffect(() => {
    document.title = "YezidiLink";
  }, []);

  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/dashboard">
            <FontAwesomeIcon icon={faHome} />
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{appconstant.UserSigned}</Breadcrumb.Item>
      </Breadcrumb>

      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <div className="content-e">
          <div class="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 system-line">
            <h6 class="text-white text-capitalize ps-3">
              {appconstant.UserSigned}
            </h6>
            <div className="search-button">
              <input
                type="text"
                onChange={handleSearch}
                className="iocn-search  search-bar"
                placeholder="Search"
                value={searchValue}
              />
            </div>
          </div>

          <Table
            className="unser-manage-table"
            dataSource={dataSource}
            columns={columns}
            showSorterTooltip={false}
            loading={loading}
            pagination={false}
            onChange={(x, y, col) => handleChange(col)}
          />
          <Pagination
            showTitle={false}
            className="pagination-wrapper"
            total={paginationData?.totalCount}
            current={paginationData?.currentPage}
            onChange={(page) => handleChange(null, page)}
          />
        </div>
      </div>
    </div>
  );
};
export default UserSigned;
