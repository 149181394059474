import { reduxConstant } from "../../constants/appconstant";

const initialState = {
  isLoading: false,
  globalList: [],

};

export const globalmanagementReducer = (state = initialState, action) => {
    switch (action.type) {
      case reduxConstant.CREATE_ANNOUCMENT_LOAD:
        return { ...state, isLoading: true };
      case reduxConstant.CREATE_ANNOUCMENT_SUCCESS:
        return {
          ...state,
          isLoading: false,
        };

    case reduxConstant.CREATE_ANNOUCMENT_FAILED : 
        return {
            ...state,
            isLoading : false
        }

        case reduxConstant.API_GLOBAL_LIST_LOAD :
          return {
              ...state,
              isLoading : true,
             
          }
      case reduxConstant.API_GLOBAL_LIST_SUCCESS : 
          return {
              ...state,
              globalList : action?.result?.data,
              isLoading:false,
             
          }
      case reduxConstant.API_GLOBAL_LIST_FAILED: 
          return {
              ...state,
              isLoading : false
          }
  
      //default
      default:
        return { ...state };
    }
  };
  