import { combineReducers } from "redux";
import { authReducer } from "./Auth";
import { userManagementReducer } from "./userManagement";
import { gameManagementReducer } from "./gameManagment";
import { contactManagementReducer } from "./contactManagementReducer";
import { deleteManagementReducer } from "./deleteManagement";
import { globalmanagementReducer } from "./globalManagement";

// import appconstant from "../../themes/appconstant";
const rootReducer = combineReducers({
  authReducer,
  userManagementReducer,
  gameManagementReducer,
  contactManagementReducer,
  deleteManagementReducer,
  globalmanagementReducer
  
});

// const rootReducer = (state, action) => {
//     if (action.type === reduxConstant.RESET_STORE) state = undefined;
//     return appReducer(state, action);
// };
export default rootReducer;
