import React, { useLayoutEffect } from "react";
import { Layout, Input, message } from "antd";
import images from "../../themes/appImage";
import {  useNavigate, useLocation } from "react-router-dom";
import appconstant from "../../themes/appconstant";
import { resetPassword, forgotLinkValid } from "../../redux/Action/Auth";
import { useDispatch, useSelector } from "react-redux";
import {  useFormik } from "formik";
import * as Yup from "yup";

const ResetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  let tokenV = location.search.split("=")[1] ;
  const token = tokenV.split("&")[0];
  const linkFetched = useSelector((state) => state.authReducer.linkFetched);

  useLayoutEffect(() => {
    dispatch(forgotLinkValid({ token }));
    document.title = linkFetched ? 'YezidiLink' : 'Link Expired';
  }, [dispatch, token, linkFetched]);

  const formik = useFormik({
    initialValues: {
      password: "",
      confirmPassword: ""
    },
    onSubmit: (values) => {
      dispatch(resetPassword({ token, password: values.password }))
        .then((response) => {
          message.success("Password reset successfully");
          navigate("/login");
        })
        .catch((error) => {
          message.error(error.response?.data?.message || "An error occurred. Please try again.");
        });
    },
    validationSchema: Yup.object().shape({
      password: Yup.string()
        .required("Please enter new password.")
        .min(8, 'New password must include 8 characters, 1 upper case letter, 1 lower case letter, 1 numeric value, 1 special character and no spaces.')
        .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{};:,<.>/?]).+$/, "New Password must include 8 characters, 1 upper case letter, 1 lower case letter, 1 numeric value, 1 special character and no spaces.")
        .test("trim", "Please enter new password.", (value) => value?.trim()?.length > 0),
      confirmPassword: Yup.string()
        .required('Please enter confirm new password.')
        .oneOf([Yup.ref("password"), null], 'New password and confirm new password must be same.')
        .test("trim", "Please enter confirm new password.", (value) => value?.trim()?.length > 0)
    }),
  });


  return (
    linkFetched ? (
      <div className="image-bg">
        <div className="page-header">
          <div className="main-div">
            <div className="login-form">
              <img style={{ height: "150px" }} src={images.logo} alt="Logo" />
              <h3>Reset Password</h3>
              <form onSubmit={formik.handleSubmit}>
                <div className="user user_input">
                  <div style={{marginBottom:"20px"}}>
                    <Input.Password
                      placeholder="New Password"
                      name="password"
                      onChange={(e) => {
                        let data = e.target.value.replace(/\s/g, "");
                        formik.setFieldValue("password", data);
                      }}
                      value={formik.values.password}
                    />
                    {formik.errors.password && formik.touched.password ? (
                      <div className="error" style={{marginLeft:0,textAlign:'start'}}>{formik.errors.password}</div>
                    ) : null}
                  </div>
                  <div style={{marginBottom:"20px"}}>
                    <Input.Password
                      placeholder="Confirm New Password"
                      name="confirmPassword"
                      onChange={(e) => {
                        let data = e.target.value.replace(/\s/g, "");
                        formik.setFieldValue("confirmPassword", data);
                      }}
                      value={formik.values.confirmPassword}
                    />
                    {formik.errors.confirmPassword && formik.touched.confirmPassword ? (
                      <div className="error" style={{marginLeft:0, textAlign:'start'}}>{formik.errors.confirmPassword}</div>
                    ) : null}
                  </div>
                </div>
                <button className="btns-app" type="submit">
                  <div className="btns-link">
                    <div className="btn-txt">{appconstant.update}</div>
                    <div className="btn-image">
                 
                    </div>
                  </div>
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div className="image-bg">
        <div className="page-header">
          <div className="main-div">
            <div className="login-form">
              <img style={{ height: "150px" }} src={images.logo} alt="Logo" />
              <h3 style={{ color: "rgba(118, 72, 218, 1)" }}>Your reset password link has been expired.</h3>
              <div className="user user_input">
                <h4 >Copyright 2024 © YezidiLink. All rights reserved.</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
};

export default ResetPassword;
