import React, { useState, useEffect, useRef } from "react";
import {
  Layout,
  Breadcrumb,
  Select,
  Input,
  Button,
  Tooltip,
  Image,
} from "antd";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteImgAction,
  editUserAction,
  imgDeleteAction,
  viewUserAction,
} from "../../redux/Action/Auth";
import appconstant from "../../themes/appconstant";
import { reduxConstant } from "../../constants/appconstant";

const { Content } = Layout;
const { Option } = Select;

const EditUserDetail = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const userDetail = useSelector(
    (state) => state.userManagementReducer?.userDetail
  );
  const [formImage , setFormImage] = useState();
  const [image, setImage] = useState();
  const isDeleteSuccess = useSelector(
    (state) => state.userManagementReducer?.isDeleteSuccess
  );

  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    nickName: "",
    gender: "",
    user: userDetail?._id || "",
  });

  useEffect(() => {
    document.title = "YezidiLink";
    window.scrollTo(0, 0);

    const data = {
      user: location?.state,
    };
    dispatch(viewUserAction(data));
  }, [dispatch, location]);

  useEffect(() => {
    if (isDeleteSuccess) {
      const data = {
        user: location?.state,
      };
      dispatch(viewUserAction(data));
    }
  }, [isDeleteSuccess]);

  useEffect(() => {
    if (userDetail) {
      setFormData({
        firstName: userDetail.firstName || "",
        lastName: userDetail.lastName || "",
        nickName: userDetail.nickName || "",
        gender: userDetail.gender || "",
        user: userDetail?._id || "",
      });
      // setImage(userDetail?.image);
    }
  }, [userDetail]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSelectChange = (value) => {
    setFormData({
      ...formData,
      gender: value,
    });
  };

  const handleSubmit = () => {
    const dataToSend = new FormData();
    // Append the form data
    Object.entries(formData).forEach(([key, value]) => {
      dataToSend.append(key, value);
    });
   
    if (image) {
      dataToSend.append("image", formImage); 
    }

    dispatch(editUserAction(dataToSend)); 
  };

  const handleDeleteImage = (imagePath, index) => {
    const data = {
      user: userDetail?._id,
      path: imagePath,
    };
    dispatch(imgDeleteAction(data));
  };

  const handleImageChange = (event) => {
    const file = event.target.files[0]; // Get the selected file
    if (file) {
      setFormImage(file);
      const reader = new FileReader();
      reader.onloadend = () => {
        setImage(reader.result); // Update state with new image data URL
      };
      reader.readAsDataURL(file); // Read the file as a data URL
    }
  };

  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/dashboard">
            <FontAwesomeIcon icon={faHome} />
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/usermanagement">{appconstant.UserManagement}</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{appconstant.editUser}</Breadcrumb.Item>
      </Breadcrumb>
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <div className="content-e">
          <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 system-line">
            <h6 className="text-white text-capitalize ps-3">
              {appconstant.editUser}
            </h6>
          </div>

          <div className="datacenter">
            <img
              src={
                image
                  ? image
                  : `${reduxConstant.IMAGE_URL}/${userDetail?.image}`
              } // Update to use state
              style={{ width: "100px", cursor: "pointer" }}
              alt="Profile"
              onClick={() => fileInputRef.current.click()}
            />
            <input
              type="file"
              accept="image/*"
              onChange={handleImageChange}
              style={{ display: "none" }}
              ref={fileInputRef}
            />
          </div>

          <div className="wrapper_line">
            <div className="gallery">
              <h3>Gallery Images</h3>
              <div
                className="gallery-images"
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                {userDetail?.galleryImage?.map((image, index) => (
                  <div key={index}>
                    <Image
                      src={`${reduxConstant.IMAGE_URL}/${image}`}
                      style={{
                        width: "150px",
                        height: "150px",
                        borderRadius: "100%",
                      }}
                      alt={`Gallery Image ${index + 1}`}
                    />
                    <Tooltip title="Delete">
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          marginTop: "5px",
                          cursor: "pointer",
                        }}
                      >
                        <FontAwesomeIcon
                          icon={faTrash}
                          className="delete-icon"
                          onClick={() => handleDeleteImage(image, index)}
                          width={25}
                        />
                      </div>
                    </Tooltip>
                  </div>
                ))}
              </div>
            </div>
          </div>

          <div className="wrapper_line">
            <div className="form-group">
              <label>{appconstant.firstName}</label>
              <Input
                name="firstName"
                value={formData.firstName}
                onChange={handleChange}
                className="form-control"
                placeholder="First Name"
              />
            </div>
            <div className="form-group">
              <label>{appconstant.lastname}</label>
              <Input
                name="lastName"
                value={formData.lastName}
                onChange={handleChange}
                className="form-control"
                placeholder="Last Name"
              />
            </div>
            <div className="form-group">
              <label>{appconstant.nickName}</label>
              <Input
                name="nickName"
                value={formData.nickName}
                onChange={handleChange}
                className="form-control"
                placeholder="Nick Name"
              />
            </div>
            <div className="form-group">
              <label>{appconstant.gender}</label>
              <Select
                value={formData.gender}
                onChange={handleSelectChange}
                className="form-control"
              >
                <Option value="Male">{appconstant.male}</Option>
                <Option value="Female">{appconstant.female}</Option>
              </Select>
            </div>

            <div className="form-group">
              <label>{appconstant.EmailAddress}</label>
              <Input
                value={userDetail?.email || "N/A"}
                readOnly
                className="form-control"
              />
            </div>
            <div className="form-group">
              <label>{appconstant.phone}</label>
              <Input
                value={userDetail?.phone || "N/A"}
                readOnly
                className="form-control"
              />
            </div>
            <div className="form-group">
              <label>{appconstant.subscribe}</label>
              <Input
                value={userDetail?.subscription || "N/A"}
                readOnly
                className="form-control"
              />
            </div>
            <div className="form-group">
              <label>{appconstant.nextdata}</label>
              <Input
                value={userDetail?.nextBillingDate || "N/A"}
                readOnly
                className="form-control"
              />
            </div>
            <div className="form-group">
              <label>{appconstant.status}</label>
              <Input
                value={userDetail?.status || "N/A"}
                readOnly
                className="form-control"
              />
            </div>
          </div>

          <div className="userdataheading">Other Profile Details</div>
          <div className="data-item">
            <div className="left">
              <div className="inner">
                <p className="left-text">{appconstant.Age}</p>
                <p className="right-text">{userDetail?.age || "N/A"}</p>
              </div>
              <div className="inner">
                <p className="left-text">{appconstant.Children}</p>
                <p className="right-text">{userDetail?.children || "N/A"}</p>
              </div>
              <div className="inner">
                <p className="left-text">{appconstant.Height}</p>
                <p className="right-text">{userDetail?.height || "N/A"}</p>
              </div>
              <div className="inner">
                <p className="left-text">{appconstant.Interest}</p>
                <p className="right-text">{userDetail?.interest || "N/A"}</p>
              </div>
              <div className="inner">
                <p className="left-text">{appconstant.ReligiousAfliation}</p>
                <p className="right-text">
                  {userDetail?.religiousAffiliation || "N/A"}
                </p>
              </div>
              <div className="inner">
                <p className="left-text">{appconstant.Education}</p>
                <p className="right-text">{userDetail?.education || "N/A"}</p>
              </div>
              <div className="inner">
                <p className="left-text">{appconstant.ValuesandBeliefs}</p>
                <p className="right-text">
                  {userDetail?.valuesAndBeliefs || "N/A"}
                </p>
              </div>
              <div className="inner">
                <p className="left-text">{appconstant.Traits}</p>
                <p className="right-text">{userDetail?.traits || "N/A"}</p>
              </div>
              <div className="inner">
                <p className="left-text">{appconstant.Headlines}</p>
                <p className="right-text">{userDetail?.headlines || "N/A"}</p>
              </div>
            </div>
            <div className="right">
              <div className="inner">
                <p className="left-text">{appconstant.Relationship}</p>
                <p className="right-text">
                  {userDetail?.relationshipStatus || "N/A"}
                </p>
              </div>
              <div className="inner">
                <p className="left-text">{appconstant.ZodiacSign}</p>
                <p className="right-text">{userDetail?.zodiacSign || "N/A"}</p>
              </div>
              <div className="inner">
                <p className="left-text">{appconstant.Weight}</p>
                <p className="right-text">{userDetail?.weight || "N/A"}</p>
              </div>
              <div className="inner">
                <p className="left-text">{appconstant.CurrentLocation}</p>
                <p className="right-text">
                  {userDetail?.currentLocation || "N/A"}
                </p>
              </div>
              <div className="inner">
                <p className="left-text">{appconstant.Occupation}</p>
                <p className="right-text">{userDetail?.occupation || "N/A"}</p>
              </div>
              <div className="inner">
                <p className="left-text">{appconstant.Relationship}</p>
                <p className="right-text">
                  {userDetail?.relationshipStatus || "N/A"}
                </p>
              </div>
              <div className="inner">
                <p className="left-text">{appconstant.DealBreakers}</p>
                <p className="right-text">
                  {userDetail?.dealBreakers || "N/A"}
                </p>
              </div>
              <div className="inner">
                <p className="left-text">{appconstant.BioOrDescription}</p>
                <p className="right-text">{userDetail?.bio || "N/A"}</p>
              </div>
            </div>
          </div>

          <div className="up-cent">
            <Button type="primary" onClick={handleSubmit}>
              {appconstant.buttonupate}
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EditUserDetail;
