import React, { useState, useEffect } from "react";
import { Layout, Breadcrumb } from "antd";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { viewUserAction } from "../../redux/Action/Auth";
import appconstant from "../../themes/appconstant";
import images from "../../themes/appImage";
import { reduxConstant } from "../../constants/appconstant";
import {Image } from "antd";

const { Content } = Layout;

const UsesrDetail = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const userDetail = useSelector((state) => state.userManagementReducer?.userDetail);

  useEffect(() => {
    document.title = "YezidiLink";
    window.scrollTo(0, 0);

    const data = {
      user: location?.state
    };
    dispatch(viewUserAction(data));
  }, [dispatch, location]);

  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/dashboard">
            <FontAwesomeIcon icon={faHome} />
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/usermanagement"> {appconstant.UserManagement} </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{appconstant.viewUser}</Breadcrumb.Item>
      </Breadcrumb>
      <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
        <div className="content-e">
          <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 system-line">
            <h6 className="text-white text-capitalize ps-3">{appconstant.viewUser}</h6>
          </div>

          <div className="datacenter">
          <img src ={`${reduxConstant.IMAGE_URL}/${userDetail?.image}`} style={{ width: "100px",  }} />
          </div>
          <div className="wrapper_line">
          <div className="gallery">
            <h3>Gallery Images</h3>
            <div className="gallery-images" style={{ display:"flex" , justifyContent : "space-between"}}>
              {userDetail?.galleryImage?.map((image, index) => (
                <Image
                  key={index}
                  src={`${reduxConstant.IMAGE_URL}/${image}`}
                  style={{ width: "150px", height:"150px" ,borderRadius : "100%" }}
                />
              ))}
            </div>
            </div>
            </div>

          <div className="wrapper_line">
            <div className="fields">
              <label>{appconstant.firstName}</label>
              <p>{userDetail?.firstName}</p>
            </div>
            <div className="fields">
              <label>{appconstant.lastname}</label>
              <p>{userDetail?.lastName}</p>
            </div>
            <div className="fields">
              <label>{appconstant.nickName}</label>
              <p>{userDetail?.nickName || "N/A"}</p>
            </div>
            <div className="fields">
              <label>{appconstant.gender}</label>
              <p>{userDetail?.gender || "N/A"}</p>
            </div>
            <div className="fields">
              <label>{appconstant.EmailAddress}</label>
              <p>{userDetail?.email || "N/A"}</p>
            </div>
            <div className="fields">
              <label>{appconstant.phone}</label>
              <p>{userDetail?.phone || "N/A"}</p>
            </div>
            <div className="fields">
              <label>{appconstant.subscribe}</label>
              <p>{userDetail?.subscription || "N/A"}</p>
            </div>
            <div className="fields">
              <label>{appconstant.nextdata}</label>
              <p>{userDetail?.nextRenewalDate || "N/A"}</p>
            </div>
            <div className="fields">
              <label>{appconstant.status}</label>
              <p>{userDetail?.subscriptionStatus || "N/A"}</p>
            </div>
          </div>

          <div className="userdataheading">Other Profile Details</div>

          <div className="data-item">
            <div className="left">
              <div className="inner">
                <p className="left-text">{appconstant.Age}</p>
                <p className="right-text">{userDetail?.age || "N/A"}</p>
              </div>
              <div className="inner">
                <p className="left-text">{appconstant.Children}</p>
                <p className="right-text">{userDetail?.children || "N/A"}</p>
              </div>
              <div className="inner">
                <p className="left-text">{appconstant.Height}</p>
                <p className="right-text">{userDetail?.height ? `${userDetail.height} cm` : "N/A"}</p>
              </div>
              <div className="inner">
                <p className="left-text">{appconstant.Interest}</p>
                <p className="right-text">{userDetail?.interest?.join(", ") || "N/A"}</p>
              </div>
              <div className="inner">
                <p className="left-text">{appconstant.ReligiousAfliation}</p>
                <p className="right-text">{userDetail?.religiousAffiliation || "N/A"}</p>
              </div>
              <div className="inner">
                <p className="left-text">{appconstant.Education}</p>
                <p className="right-text">{userDetail?.education || "N/A"}</p>
              </div>
              <div className="inner">
                <p className="left-text">{appconstant.ValuesandBeliefs}</p>
                <p className="right-text">{userDetail?.valueAndBeliefs?.join(", ") || "N/A"}</p>
              </div>
              <div className="inner">
                <p className="left-text">Facebook:</p>
                <p className="right-text">{userDetail?.facebook || "N/A"}</p>
              </div>
              <div className="inner">
                <p className="left-text">Instagram:</p>
                <p className="right-text">{userDetail?.instagram || "N/A"}</p>
              </div>
              <div className="inner">
                <p className="left-text">Tiktok:</p>
                <p className="right-text">{userDetail?.tiktok || "N/A"}</p>
              </div>
              <div className="inner">
                <p className="left-text">{appconstant.Traits}</p>
                <p className="right-text">{userDetail?.traits?.join(", ") || "N/A"}</p>
              </div>
              <div className="inner">
                <p className="left-text">{appconstant.Headlines}</p>
                <p className="right-text" >{userDetail?.headlines || "N/A"}</p>
              </div>
            </div>

            <div className="right">
              <div className="inner">
                <p className="left-text">{appconstant.Relationship}</p>
                <p className="right-text">{userDetail?.relationshipStatus || "N/A"}</p>
              </div>
              <div className="inner">
                <p className="left-text">{appconstant.ZodiacSign}</p>
                <p className="right-text">{userDetail?.zodiac || "N/A"}</p>
              </div>
              <div className="inner">
                <p className="left-text">{appconstant.Weight}</p>
                <p className="right-text">{userDetail?.weight || "N/A"}</p>
              </div>
              <div className="inner">
                <p className="left-text">{appconstant.CurrentLocation}</p>
                <p className="right-text">{userDetail?.city || "N/A"}, {userDetail?.country || "N/A"}</p>
              </div>
              <div className="inner">
                <p className="left-text">{appconstant.Occupation}</p>
                <p className="right-text">{userDetail?.occupation || "N/A"}</p>
              </div>
              <div className="inner">
                <p className="left-text">{appconstant.RelationshipGoals}</p>
                <p className="right-text">{userDetail?.relationshipGoals?.join(", ") || "N/A"}</p>
              </div>
              <div className="inner">
                <p className="left-text">{appconstant.DealBreakers}</p>
                <p className="right-text">{userDetail?.dealBreakers?.join(", ") || "N/A"}</p>
              </div>
              <div className="inner">
                <p className="left-text">{appconstant.BioOrDescription}</p>
           <p className="right-text">{userDetail?.bio || "N/A"}</p> 
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UsesrDetail;
