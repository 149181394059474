import React from "react";
import { Navigate } from "react-router-dom";

const PrivateRoute = ({ children }) => {
    let isAuthenticated = localStorage.getItem('token')
    return !isAuthenticated ? <Navigate to='/' /> : children
};


export const PublicRoute = ({ children }) => {
    let tokenCheck = localStorage.getItem('token')
    return !tokenCheck ? children : <Navigate to='/' />
}

export default PrivateRoute;

