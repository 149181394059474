import React, { useState, useEffect } from "react";
import { Layout, Breadcrumb ,Image } from "antd";
import { Link, useLocation } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { useDispatch, useSelector } from "react-redux";
import { viewUserAction } from "../../redux/Action/Auth";
import appconstant from "../../themes/appconstant";
import images from "../../themes/appImage";
import { reduxConstant } from "../../constants/appconstant";

const { Content } = Layout;

const Deletedetails = () => {
  const dispatch = useDispatch();
  const [isVisible, setVisible] = useState(false);
  const location = useLocation();
  const userDetail = useSelector(
    (state) => state.userManagementReducer?.userDetail
  );

  useEffect(() => {
    document.title = "YezidiLink";
    window.scrollTo(0, 0);

    const data = {
      user: location?.state,
    };
    dispatch(viewUserAction(data));
  }, [dispatch, location]);

  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/dashboard">
            <FontAwesomeIcon icon={faHome} />
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/deleteuser"> {appconstant.deleteuser} </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{appconstant.deleteduser}</Breadcrumb.Item>
      </Breadcrumb>
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <div className="content-e">
          <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 system-line">
            <h6 className="text-white text-capitalize ps-3">
              {appconstant.deleteduser}
            </h6>
          </div>
          <div className="datacenter">
          <Image src ={`${reduxConstant.IMAGE_URL}/${userDetail?.image}`} style={{ width: "100px", Height: "200px" }} />


                    </div>
          <div className="wrapper_line">
            <div className="fields">
              <label>{appconstant.firstName}</label>
              <p>{userDetail?.firstName || "N/A"}</p>
            </div>
            <div className="fields">
              <label>{appconstant.lastname}</label>
              <p>{userDetail?.lastName || "N/A"}</p>
            </div>
            <div className="fields">
              <label>{appconstant.nickName}</label>
              <p>{userDetail?.nickName || "N/A"}</p>
            </div>
            <div className="fields">
              <label>{appconstant.gender}</label>
              <p>{userDetail?.gender || "N/A"}</p>
            </div>
            <div className="fields">
              <label>{appconstant.dateOfbirth}</label>
              <p>{userDetail?.dateOfBirth || "N/A"}</p>
            </div>
            <div className="fields">
              <label>{appconstant.EmailAddress}</label>
              <p>{userDetail?.email || "N/A"}</p>
            </div>
            <div className="fields">
              <label>{appconstant.phone}</label>
              <p>
                {userDetail?.countryCode
                  ? `${userDetail.countryCode} ${userDetail.phone}`
                  : "N/A"}
              </p>
            </div>
            <div className="fields">
              <label>{appconstant.reason}</label>
              <p className="txt">{userDetail?.reason || "N/A"}</p>
            </div>
          </div>
          <div className="btns-app">
            <div className="btns-image">
              <Link className="btns-link" to="/deleteuser">
                <div className="btn-txt">{appconstant.submit}</div>
              </Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Deletedetails;
