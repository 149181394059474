import { reduxConstant } from "../../constants/appconstant";

const initialState = {
    isLoading: false,
    deleteList: [],
    pagination: {},
    deleteSuccess : null
}

export const deleteManagementReducer = (state = initialState, action) => {

    switch (action.type){
        case reduxConstant.API_DELETE_LIST_LOAD :
            return {
                ...state,
                isLoading : true,
                deleteSuccess : false
               
            }
        case reduxConstant.API_DELETE_LIST_SUCCESS : 
            return {
                ...state,
                deleteList : action?.result?.data,
                isLoading:false,
                deleteSuccess : true
               
            }
        case reduxConstant.API_DELETE_LIST_FAILED: 
            return {
                ...state,
                isLoading : false
            }
            case reduxConstant.API_DELETE_USER_LOAD :
                return {
                    ...state,
                    isLoading : true,
                    deleteSuccess : false
                   
                }
            case reduxConstant.API_DELETE_USER_SUCCESS : 
                return {
                    ...state,
                    deleteList : action?.result?.data,
                    isLoading:false,
                    deleteSuccess : true
                   
                }
            case reduxConstant.API_DELETE_USER_FAIL: 
                return {
                    ...state,
                    isLoading : false
                }

        default : return {...state}

    }
}