export const reduxConstant = {

  // SERVER_URL: "http://192.168.3.177:3020/api/v1", //local
  // IMAGE_URL: "http://192.168.3.177:3020",


  SERVER_URL: "https://api.yazidilink.com/api/v1",    
  IMAGE_URL: "https://api.yazidilink.com",

  // SERVER_URL: "http://1.6.98.141:9067/api/v1",    
  // IMAGE_URL: "http://1.6.98.141:9067",


  //AUTH
  LOGIN_LOAD: "LOGIN_LOAD",
  LOGIN_SUCCESS: "LOGIN_SUCCESS",
  LOGIN_FAIL: "LOGIN_FAIL",
  FORGOT_LOAD: "FORGOT_LOAD",
  FORGOT_SUCCESS: "FORGOT_SUCCESS",
  FORGOT_FAIL: "FORGOT_FAIL",
  DASHBOARD_LOAD: "DASHBOARD_LOAD",
  DASHBOARD_SUCCESS: "DASHBOARD_SUCCESS",
  DASHBOARD_FAILURE: "DASHBOARD_FAILURE",
  LOGOUT_LOAD: "LOGOUT_LOAD",
  LOGOUT_SUCCESS: "LOGOUT_SUCCESS",
  LOGOUT_FAIL: "LOGOUT_FAIL",
  CHANGE_PASSWORD_LOAD: "CHANGE_PASSWORD_LOAD",
  CHANGE_PASSWORD_SUCCESS: "CHANGE_PASSWORD_SUCCESS",
  CHANGE_PASSWORD_FAIL: "CHANGE_PASSWORD_FAIL",
  RESET_PASSWORD_LOAD: "RESET_PASSWORD_LOAD",
  RESET_PASSWORD_SUCCESS: "RESET_PASSWORD_SUCCESS",
  RESET_PASSWORD_FAIL: "RESET_PASSWORD_FAIL",
  FORGOT_LINK_LOAD: "FORGOT_LINK_LOAD",
  FORGOT_LINK_SUCCESS: "FORGOT_LINK_SUCCESS",
  FORGOT_LINK_FAIL: "FORGOT_LINK_FAIL",
  SEND_NOTIFICATION_LOAD: "SEND_NOTIFICATION_LOAD",
  SEND_NOTIFICATION_SUCCESS: "SEND_NOTIFICATION_SUCCESS",
  SEND_NOTIFICATION_FAIL: "SEND_NOTIFICATION_FAIL",

  //user management
  API_User_List_LOAD: "API_User_List_LOAD",
  API_USER_LIST_SUCCESS: "API_USER_LIST_SUCCESS",

  API_BLOCK_USER_LOAD: "API_BLOCK_USER_LOAD",
  API_BLOCK_USER_SUCCESS: "API_BLOCK_USER_SUCCESS",

  API_VIEW_USER_LOAD: "API_VIEW_USER_LOAD",
  API_VIEW_USER_SUCCESS: "API_VIEW_USER_SUCCESS",

  API_EDIT_USER_LOAD: "API_EDIT_USER_LOAD",
  API_EDIT_USER_SUCCESS: "API_EDIT_USER_SUCCESS",

  API_USER_ADD_COIN_LOAD: "API_USER_ADD_COIN_LOAD",
  API_USER_ADD_COIN_SUCCESS: "API_USER_ADD_COIN_SUCCESS",

  //user fail
  API_USER_MANAGEMENT_FAIL: "API_USER_MANAGEMENT_FAIL",

  //Shop management
  API_SHOP_LIST_LOAD: "API_SHOP_LIST_LOAD",
  API_SHOP_LIST_SUCCESS: "API_SHOP_LIST_SUCCESS",

  API_VIEW_SHOP_LOAD: "API_VIEW_SHOP_LOAD",
  API_VIEW_SHOP_SUCCESS: "API_VIEW_SHOP_SUCCESS",

  API_ADD_SHOP_ITEM_LOAD: "API_ADD_SHOP_ITEM_LOAD",
  API_ADD_SHOP_ITEM_SUCCESS: "API_ADD_SHOP_ITEM_SUCCESS",

  //user fail
  API_SHOP_MANAGEMENT_FAIL: "API_SHOP_MANAGEMENT_FAIL",
  ADD_SHOP_DATA_FAIL: "ADD_SHOP_DATA_FAIL",
  //game management

  API_GET_GAME_LIST_LOAD: "API_GET_GAME_LIST_LOAD",
  API_GET_GAME_LIST_SUCCESS: "API_GET_GAME_LIST_SUCCESS",

  API_GET_GAME_LIST_FAIL: "API_GET_GAME_LIST_FAIL",

  //notification

  NOTIFICATION_LIST_LOAD: "NOTIFICATION_LIST_LOAD",
  NOTIFICATION_LIST_SUCCESS: "NOTIFICATION_LIST_SUCCESS",
  NOTIFICATION_LIST_FAIL: "NOTIFICATION_LIST_FAIL",

  NOTIFICATION_DELETE_LOAD: "NOTIFICATION_DELETE_LOAD",
  NOTIFICATION_DELETE_SUCCESS: "NOTIFICATION_DELETE_SUCCESS",
  NOTIFICATION_DELETE_FAIL: "NOTIFICATION_DELETE_FAIL",

  NOTIFICATION_EDIT_LOAD: "NOTIFICATION_EDIT_LOAD",
  NOTIFICATION_EDIT_SUCCESS: "NOTIFICATION_EDIT_SUCCESS",
  NOTIFICATION_EDIT_FAIL: "NOTIFICATION_EDIT_FAIL",

  // Tournament

  TOURNAMENT_ADD_LOAD: "TOURNAMENT_ADD_LOAD",
  TOURNAMENT_ADD_SUCCESS: "TOURNAMENT_ADD_SUCCESS",

  TOURNAMENT_LIST_LOAD: "TOURNAMENT_LIST_LOAD",
  TOURNAMENT_LIST_SUCCESS: "TOURNAMENT_LIST_SUCCESS",

  TOURNAMENT_DETAILS_LOAD: "TOURNAMENT_DETAILS_LOAD",
  TOURNAMENT_DETAILS_SUCCESS: "TOURNAMENT_DETAILS_SUCCESS",

  TOURNAMENT_BLOCK_LOAD: "TOURNAMENT_BLOCK_LOAD",
  TOURNAMENT_BLOCK_SUCCESS: "TOURNAMENT_BLOCK_SUCCESS",

  TOURNAMENT_EDIT_LOAD: "TOURNAMENT_EDIT_LOAD",
  TOURNAMENT_EDIT_SUCCESS: "TOURNAMENT_EDIT_SUCCESS",

  GET_SHOP_DATA_LOAD: "GET_SHOP_DATA_LOAD",
  GET_SHOP_DATA_SUCCESS: "GET_SHOP_DATA_SUCCESS",
  GET_SHOP_DATA_FAIL: "GET_SHOP_DATA_FAIL",

  API_SHOP_EDIT_LOAD: "API_SHOP_EDIT_LOAD",
  API_SHOP_EDIT_SUCCESS: "API_SHOP_EDIT_SUCCESS",

  ADD_SHOP_DATA_LOAD: "ADD_SHOP_DATA_LOAD",
  ADD_SHOP_DATA_SUCCESS: "ADD_SHOP_DATA_SUCCESS",
  TOURNAMENT_MANAGEMENT_FAILED: "TOURNAMENT_MANAGEMENT_FAILED",


  API_CONTACT_LIST_LOAD: "API_CONTACT_LIST_LOAD",
  API_CONTACT_LIST_SUCCESS: "API_CONTACT_LIST_SUCCESS",
  API_CONTACT_LIST_FAILED: "API_CONTACT_LIST_FAILED",


  API_DELETE_LIST_LOAD: "API_DELETE_LIST_LOAD",
  API_DELETE_LIST_SUCCESS: "API_DELETE_LIST_SUCCESS",
  API_DELETE_LIST_FAILED: "API_DELETE_LIST_FAILED",



  //////// Global Account ///////////
  CREATE_ANNOUCMENT_LOAD: "CREATE_ANNOUCMENT_LOAD",
  CREATE_ANNOUCMENT_SUCCESS: "CREATE_ANNOUCMENT_SUCCESS",
  CREATE_ANNOUCMENT_FAILED: "CREATE_ANNOUCMENT_FAILED",

  API_GLOBAL_LIST_LOAD: "GLOBAL_LIST_LOAD",
  API_GLOBAL_LIST_SUCCESS: "GLOBAL_LIST_SUCCESS",
  API_GLOBAL_LIST_FAILED: "GLOBAL_LIST_FAILED",

  /////////////// Report Management ////////////////
  API_REPORT_LIST_LOAD: "API_REPORT_LIST_LOAD",
  API_REPORT_LIST_SUCCESS: "API_REPORT_LIST_SUCCESS",
  API_REPORT_LIST_FAILED: "API_REPORT_LIST_FAILED",
  REPORT_VIEW_LOAD: "REPORT_VIEW_LOAD",
  REPORT_VIEW_SUCCESS: "REPORT_VIEW_SUCCESS",


  ///////// ID VERFICATION /////////////////////

  ID_VERIFICATION_LOAD: "ID_VERIFICATION_LOAD",
  ID_VERIFICATION_SUCCESS: "ID_VERIFICATION_SUCCESS",
  ID_VERIFICATION_FAIL: "ID_VERIFICSTION_FAIL",

  API_DELETE_USER_LOAD: "API_DELETE_USER_LOAD",
  API_DELETE_USER_SUCCESS: "API_DELETE_USER_SUCCESS",
  API_DELETE_USER_FAIL: "API_DELETE_USER_FAIL"



};

export const msgConstant = {
  messageStatus500: "Something went wrong.",
};
