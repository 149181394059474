import { put, call, takeLatest } from "@redux-saga/core/effects";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { navigate } from "../../utils/navigator";
import { msgConstant, reduxConstant } from "../../constants/appconstant";
import { getContactListApi } from "../Api";

function* getContactListSaga(action) {
  try {
    console.log(action, "this is the action");
    const response = yield call(getContactListApi, action.data);
    console.log(response.result, "this is my response");

    if (response?.status == 0 ) {
      yield put({
        type: reduxConstant.API_CONTACT_LIST_SUCCESS,
        result: response.result,
      });
    } else {
      toast.error(response.result.message);
      yield put({ type: reduxConstant.API_CONTACT_LIST_FAILED });
    }
  } catch (error) {
    console.error("Error in getContactListSaga:", error);
    toast.error(msgConstant.messageStatus500);
    yield put({ type: reduxConstant.API_CONTACT_LIST_FAILED });
  }
}

export default function* contactManagementSaga() {
  yield takeLatest(reduxConstant.API_CONTACT_LIST_LOAD, getContactListSaga);
}
