import { reduxConstant } from "../../constants/appconstant";
import axios from "axios";

let APIKit = axios.create({
    baseURL: reduxConstant.SERVER_URL,
    timeout: 600000,
});

APIKit.interceptors.request.use(async (config) => {
    const token = localStorage.token;
    if (token) {
        config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
});
export default APIKit;
