import { message } from "antd"
import Login from "../components/login"

const appconstant = {
  unBlock: "Unblock",
  changepassword: 'Change Password',
  Oldpassword: 'Old Password',
  NewPassword: 'New Password',
  headings: 'Headings',
  heading: "Heading",
  details: 'Details',
  ConfirmNewPassword: 'Confirm New Password',
  contactus: 'Contact Us',
  home: 'Dashboard',
  contactD: 'Contact Us Details',
  login: 'Login',
  EmailAddress: 'Email Address',
  eventManagement: 'Event Management',
  UserManagement: 'User Management',
  createmessage: 'Create An Announcement',
  Password: 'Password',
  ConfrmPassword: 'Confrm Password',
  settings: 'Settings',
  email: 'Email',
  contactNo: 'Contact No',
  gender: 'Gender',
  firstname: 'First Name',
  reportuser: 'Reported User Details',
  deleteduser: 'Deleted User Details',
  lastname: 'Last Name',
  male: 'Male',
  female: 'Female',
  Reason: 'Reason Of Report',
  textD: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry',
  globalmanage: 'Globlal Announcement',
  message: 'Message',
  age: 'Age',
  name: 'User Name',
  name1: "Name",
  pastannouncement: 'Past Announcement',
  view: 'View',
  edit: 'Edit',
  block: 'Block',
  gender: 'Gender',
  dateOfbirth: 'Date Of Birth',
  subscribe: 'Subscription Plan',
  nextdata: 'Next Renewal Date',
  status: 'Subscription Status',
  userReportManagement: 'User Report Management',
  tournamentManagement: "Tournament Management",
  viewUser: "User Details",
  userName: "User Name",
  firstName: "First Name",
  lastname: "Last Name",
  nickName: "Nick Name",

  John: "John",
  fullName: "Full Name",
  JohnDee: "John Dee",

  Age: 'Age:',
  Relationship: 'Relationship:',

  ZodiacSign: 'Zodiac Sign:',
  Height: 'Height:',
  Weight: 'Weight:',
  Interest: 'Interest:',
  CurrentLocation: ' Current Location:',
  ReligiousAfliation: 'Religious Affiliation:',
  Occupation: 'Occupation:',
  Children: 'Do You Have Any Children:',
  Education: 'Education:',
  RelationshipGoals: 'Relationship Goals:',
  ValuesandBeliefs: 'Values and Beliefs:',
  DealBreakers: 'Deal Breakers:',
  Traits: 'Traits:',
  BioOrDescription: 'Bio Or Description:',
  Headlines: 'Headlines:',
  faceId: 'Face Picture',
  videoId: 'Video Verifcation',
  Idproof: 'ID Verifcation',
  submit: 'Done',
  send: "Send",
  deleteuser: 'Deleted User Management',
  verification: 'User Verification',
  HeadlinesD: 'Lorem Ipsum is simply dummy text of the printing and typesetting industry.',
  Userverfication: 'User Verification',
  UserSigned: 'User Signed',
  verificationdetail: 'User Verification Details',
  userDetails: 'User Details',

  AgeD: '23',
  RelationshipD: 'Married',
  ZodiacSignD: 'Areis',
  HeightD: '165cm',
  WeightD: '50KG',
  Interestd: 'Reading',
  CurrentLocationD: ' New York',
  ReligiousAfliationD: 'Shex',
  OccupationD: 'Teacher',
  ChildrenD: 'No',
  EducationD: 'MCA',
  RelationshipGoalsD: 'N/A',
  ValuesandBeliefsD: 'Hard work',
  DealBreakersD: 'Family',
  TraitsD: 'Generosity',
  BioOrDescriptionD: 'Lorem Ipsum is  simply dummy text of the printing and typesetting industry. ',

  reason: 'Reason',
  logout: 'Logout',

  id: "	john@yopmail.com",
  birthdata: '09/05/2024',
  genderdata: 'Male',
  plan: 'Gold',
  renewaldata: '12/12/2026',
  subscriptionstatus: 'Active',
  phone: "Phone Number",
  number: "9856471254",
  editUser: "Edit User Details",
  viewTournamentDetails: "View Tournament Details",
  tournamentName: "Tournament Name",
  tournamentNameOne: "Tournament Name 1",
  tournamentDateTime: "Tournament Start Date & Time",
  totalNumberOfPlayers: "Total Number Of Players",
  totalplayers: "6",
  winningAmount: "Winning Amount",
  amountnumber: "$35",
  editTournamentDetail: "Edit Tournament Details",
  add: 'Add',
  addTournamentDetail: "Add Tournament Details",
  date: "2024-02-07 18:18:00",
  management: 'Report Management',
  delete: 'Delete ',
  notifcationManagement: 'Notification Management',
  addNotification: 'Add Notification',
  titile: 'Title',
  description: 'Description',
  editNotification: 'Edit Notification',
  update: 'Update',
  create: "Create An Announcement",
  createTournamentDetail: "Create Tournament Details",
  winer: "Winner",
  createbtn: "Send",















  // 


  //










  //
  buttonupate: 'Update'

}
export default appconstant