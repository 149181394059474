import { msgConstant, reduxConstant } from "../../constants/appconstant";
import { put, call, takeLatest } from "@redux-saga/core/effects";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { createAnnoucmentApi, getGlobalListApi } from "../Api/index";
import { navigate } from "../../utils/navigator";

function* createGlobalAnnouncmentSaga(action) {
  try {
    const response = yield call(createAnnoucmentApi, action.data);
    if (response?.status == 0 ) {
      yield put({
        type: reduxConstant.CREATE_ANNOUCMENT_SUCCESS,
        result: response?.result,
      });
      toast.success(response.result.message);
      navigate("/globalmanagement"); 


    } else {
      toast.error(response.result.message);
      yield put({ type: reduxConstant.CREATE_ANNOUCMENT_FAILED });
    }
  } catch (error) {
    toast.error(msgConstant.messageStatus500);
    yield put({ type: reduxConstant.CREATE_ANNOUCMENT_FAILED });
  }
}

function* getGlobalListsaga(action) {
  try {
    const response = yield call(getGlobalListApi, action.data);
    if (response?.status == 0 ) {
      yield put({
        type: reduxConstant.API_GLOBAL_LIST_SUCCESS,
        result: response?.result,
      });
    } else {
      toast.error(response.result.message);
      yield put({ type: reduxConstant.API_GLOBAL_LIST_FAILED });
    }
  } catch (error) {
    toast.error(msgConstant.messageStatus500);
    yield put({ type: reduxConstant.API_GLOBAL_LIST_FAILED});
  }
}



export default function* globalManagementSaga() {
  yield takeLatest(reduxConstant.CREATE_ANNOUCMENT_LOAD, createGlobalAnnouncmentSaga);
  yield takeLatest(reduxConstant.API_GLOBAL_LIST_LOAD, getGlobalListsaga);
}
