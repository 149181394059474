import React, { useState, useEffect } from "react";
import { Layout, Breadcrumb, Table, Pagination } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getContactListAction } from "../../redux/Action/Auth";
import appconstant from "../../themes/appconstant";

const { Header } = Layout;

const Contactus = () => {
  const dispatch = useDispatch();
  const [payload, setPayload] = useState({
    offset: 0,
    limit: 10,
    sort: null,
    order: null,
    search: "",
  });
  const [searchValue, setSearchValue] = useState("");

  const { contactList } = useSelector((state) => state.contactManagementReducer);
  const paginationData = contactList?.pagination;
  const loading = useSelector((state) => state.contactManagementReducer.isLoading);

  useEffect(() => {
    dispatch(getContactListAction({ ...payload, search: searchValue, offset: 0 }));
  }, [searchValue]);

  const handleSearch = (e) => {
    const searchData = e?.target?.value;
    if (!searchData?.startsWith(" ")) setSearchValue(searchData);
  };

  const handleChange = (col, page) => {
    const tempPayload = { ...payload };

    if (col && col.columnKey) {
      tempPayload["sort"] = col?.columnKey || payload?.sort;
      tempPayload["order"] =
        col?.order === "ascend"
          ? 1
          : col?.order === "descend"
          ? -1
          : payload?.order;
    } else if (page) {
      tempPayload["offset"] = (page - 1) * 10;
    }
    setPayload(tempPayload);
    dispatch(getContactListAction({ ...tempPayload }));
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0'); 
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  }

  const dataSource = contactList?.list?.map((e) => ({
    _id: e?._id,
    email: e?.email,
    name: e?.name,
    phone: e?.phone,
    message: e?.message,
    date: formatDate(e.createdAt),
  }));

  const columns = [
    {
      title: "Sr. No.",
      dataIndex: "srno",
      key: "srno",
      sorter: false,
      render: (value, item, index) => payload.offset + (index + 1),
    },
    {
      title: "Email Address",
      dataIndex: "email",
      key: "email",
      sorter: true,
    },
    {
      title: "Phone Number",
      dataIndex: "phone",
      key: "phone",
      sorter: true,
    },
    {
      title: "Message",
      dataIndex: "message",
      key: "message",
      sorter: true,
    },
    {
      title: 'Date',
      dataIndex: 'date',
      key: 'date',
      sorter: true,
    }

  ];

  useEffect(() => {
    document.title = "YezidiLink";
  }, []);

  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/dashboard">
            <FontAwesomeIcon icon={faHome} />
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{appconstant.contactus}</Breadcrumb.Item>
      </Breadcrumb>

      <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
        <div className="content-e">
          <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 system-line">
            <h6 className="text-white text-capitalize ps-3">{appconstant.contactus}</h6>
            <div className="search-button">
              <input
                type="text"
                onChange={handleSearch}
                className="iocn-search search-bar"
                placeholder="Search"
                value={searchValue}
              />
            </div>
          </div>

          <Table
            className="unser-manage-table"
            dataSource={dataSource}
            columns={columns}
            showSorterTooltip={false}
            loading={loading}
            pagination={false}
            onChange={(x, y, col) => handleChange(col)}
          />
          <Pagination
            showTitle={false}
            className="pagination-wrapper"
            total={paginationData?.totalCount}
            current={paginationData?.currentPage}
            onChange={(page) => handleChange(null, page)}
          />
        </div>
      </div>
    </div>
  );
};

export default Contactus;
