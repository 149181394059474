import downlaod from '../images/userToday.svg'
import lastSix from "../images/last-sixM1.png"
import lastWeek from "../images/lastWeek.png";
import lastMonth from "../images/lastMonth.png";
import lastThree from "../images/lastThree.png"
import lastSixx from "../images/lastSix.png";
import lastYear from "../images/lastYear.png";

const images = {
    download: downlaod,
    forgotim:require('../images/Forgotiim.png').default,
    logo:require('../images/logo.png').default,
    logo1:require('../images/logo2.png').default,
    link:require('../images/Vector.png').default,
    link1:require('../images/Vector1.png').default,
    down: require('../images/first.png').default,
    login2: require('../images/slider.png').default,
    user7: require('../images/user4.png').default,
    usermessage:require('../images/man.png').default,
    pluss:require('../images/plus.png').default,
    userWeek:require('../images/UserWeek.svg').default,
    usermonth:require('../images/UserMonth.svg').default,
    userquarter:require('../images/User3Months.svg').default,
    thisyear:require('../images/UserYear.svg').default,
    totalGame:require('../images/first1.png').default,
    todayGame:require('../images/today-game.png').default,
    gameWeek:require('../images/game-week.png').default,
    gamemonth:require('../images/game-month.png').default,
    gamequarter:require('../images/game-quarter.png').default,
    gameyear:require('../images/game-year.png').default,
    lastsix:lastSix,
    lastWeek:lastWeek,
    lastMonth:lastMonth,
    lastThree:lastThree,
    lastSixx:lastSixx,
    lastYear:lastYear
}

export default images;