import { msgConstant, reduxConstant } from "../../constants/appconstant";
import { put, call, takeLatest } from "@redux-saga/core/effects";
import { toast } from "react-toastify";
import { navigate } from '../../utils/navigator'
import "react-toastify/dist/ReactToastify.css";
import {
  userListApi,
  blockUserApi,
  viewUserApi,
  editUserApi,
  userAddCoinApi,
  getReportListApi,
  idVerificationApi,
  reportViewApi,
  deleteImgApi,
} from "../Api/index";

function* userListSaga(action) {
  try {
    const response = yield call(userListApi, action.data);
    if (response?.status >= 0 && response?.status < 1) {
      yield put({
        type: reduxConstant.API_USER_LIST_SUCCESS,
        result: response?.result,
      });
    } else {
      toast.error(response.result.message);
      yield put({ type: reduxConstant.API_USER_MANAGEMENT_FAIL });
    }
  } catch (error) {
    toast.error(msgConstant.messageStatus500);
    yield put({ type: reduxConstant.API_USER_MANAGEMENT_FAIL });
  }
}

function* blockUserSaga(action) {
  try {
    const response = yield call(blockUserApi, action.data);
    if (response?.status == 0) {
      toast.success(response.result.message);
      yield put({
        type: reduxConstant.API_BLOCK_USER_SUCCESS,
        result: action.data,
      });
    } else {
      toast.error(response.result.message);
      yield put({ type: reduxConstant.API_USER_MANAGEMENT_FAIL });
    }
  } catch (error) {
    toast.error(msgConstant.messageStatus500);
    yield put({ type: reduxConstant.API_USER_MANAGEMENT_FAIL });
  }
}

function* addCoinUserSaga(action) {
  try {
    const response = yield call(userAddCoinApi, action.data);
    if (response?.status >= 0 && response?.status < 1) {
      toast.success(response.result.message);
      yield put({
        type: reduxConstant.API_USER_ADD_COIN_SUCCESS,
        result: action.data,
      });
    } else {
      toast.error(response.result.message);
      yield put({ type: reduxConstant.API_USER_MANAGEMENT_FAIL });
    }
  } catch (error) {
    toast.error(msgConstant.messageStatus500);
    yield put({ type: reduxConstant.API_USER_MANAGEMENT_FAIL });
  }
}

function* viewUserSaga(action) {
  try {
    const response = yield call(viewUserApi, action.data);
    if (response?.status >= 0 && response?.status < 1) {
      yield put({
        type: reduxConstant.API_VIEW_USER_SUCCESS,
        result: response.result,
      });
    } else {
      toast.error(response.result.message);
      yield put({ type: reduxConstant.API_USER_MANAGEMENT_FAIL });
    }
  } catch (error) {
    toast.error(msgConstant.messageStatus500);
    yield put({ type: reduxConstant.API_USER_MANAGEMENT_FAIL });
  }
}

function* editUserSaga(action) {
  try {
    const response = yield call(editUserApi, action.data);
    if (response.status === 0) { 
      toast.success(response.result.message);
      yield put({
        type: reduxConstant.API_EDIT_USER_SUCCESS,
        result: response.result,
      });
      navigate("/usermanagement");
    } else {
      toast.error(response.result.message);
      yield put({ type: reduxConstant.API_USER_MANAGEMENT_FAIL });
    }
  } catch (error) {
    toast.error(msgConstant.messageStatus500);
    yield put({ type: reduxConstant.API_USER_MANAGEMENT_FAIL });
  }
}

function* reportListSaga(action) {
  try {
    const response = yield call(getReportListApi, action.data);
    if (response?.status == 0 ) {
      yield put({
        type: reduxConstant.API_REPORT_LIST_SUCCESS,
        result: response.result,
      });
    
    } else {
      toast.error(response.result.message);
      yield put({ type: reduxConstant.API_REPORT_LIST_FAILED });
    }
  } catch (error) {
    toast.error(msgConstant.messageStatus500);
    yield put({ type: reduxConstant.API_REPORT_LIST_FAILED });
  }
}

function* idVerifySaga(action) {
  try {
    const response = yield call(idVerificationApi, action.data);
    if (response?.status == 0 ) {
      yield put({
        type: reduxConstant.ID_VERIFICATION_SUCCESS,
        
      });
      toast.success(response.result.message);
    
    } else {
      toast.error(response.result.message);
  
    }
  } catch (error) {
    toast.error(msgConstant.messageStatus500);
 
  }

}

function* deleImgSaga(action){
  try {
    const response = yield call(deleteImgApi, action.data);
    if (response?.status == 0 ) {
      yield put({
        type: reduxConstant.REPORT_VIEW_SUCCESS,
        
      });
      toast.success(response.result.message);
    
    } else {
      toast.error(response.result.message);
  
    }
  } catch (error) {
    toast.error(msgConstant.messageStatus500);
 
  }

}

export default function* UserManagementSaga() {
  yield takeLatest(reduxConstant.API_User_List_LOAD, userListSaga);
  yield takeLatest(reduxConstant.API_BLOCK_USER_LOAD, blockUserSaga);
  yield takeLatest(reduxConstant.API_VIEW_USER_LOAD, viewUserSaga);
  yield takeLatest(reduxConstant.API_EDIT_USER_LOAD, editUserSaga);
  yield takeLatest(reduxConstant.API_USER_ADD_COIN_LOAD, addCoinUserSaga);
  yield takeLatest(reduxConstant.API_REPORT_LIST_LOAD,reportListSaga);
  yield takeLatest(reduxConstant.ID_VERIFICATION_LOAD , idVerifySaga);
  yield takeLatest(reduxConstant.REPORT_VIEW_LOAD , deleImgSaga);
}
