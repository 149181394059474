import React, { useState, useEffect } from "react"
import { FontAwesomeIcon, } from '@fortawesome/react-fontawesome'
import { faHome, } from "@fortawesome/free-solid-svg-icons";
import { Breadcrumb } from 'antd';
import images from "../../themes/appImage";
import appconstant from "../../themes/appconstant";
import { dashboard } from '../../redux/Action/Auth'
import { useDispatch, useSelector } from 'react-redux'


const Dashboard = (props) => {

  const dispatch = useDispatch()
  const dashboardData = useSelector((state) => state.authReducer.dashboard)
  const [isVisible, setVisible] = useState(false)
  const handlewClick = () => {
    setVisible(!isVisible)
  }
  useEffect(() => {
    document.title = 'YezidiLink';
    window.scrollTo(0, 0)
    dispatch(dashboard())

  }, [])



  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item> <FontAwesomeIcon icon={faHome} /></Breadcrumb.Item>
        <Breadcrumb.Item> Dashboard</Breadcrumb.Item>
      </Breadcrumb>

      <div className="main-layout-des-page">

        <div className="dash-board-heading-text">
          <p>Total Number Of Users Registered </p>
        </div>
        <div className="main-div-2 dashboard ">
          

          <div className="datacenter user-valid-text">
            <div className="image-fle">
              <div className="total-num">
              <h1>{dashboardData?.todayJoinedUser || 0}</h1>
              </div>
              <div>
                <img src={images.download} style={{ width: '84px', Height: '150px' }} /></div>
            </div>
            <div className="user-text">
              <p> Total registered users today </p>
            </div>
          </div>



          <div className="datacenter user-valid-text">
            <div className="image-fle">
              <div className="total-num">
              <h1>{dashboardData?.lastWeekJoinedUser || 0}</h1>
              </div>
              <div>
                <img src={images.userWeek} style={{ width: '84px', Height: '150px' }} /></div>
            </div>
            <div className="user-text">
              <p>Total registered users in last week</p>
            </div>

          </div>

          <div className="datacenter user-valid-text">
            <div className="image-fle">
              <div className="total-num">
              <h1>{dashboardData?.lastMonthJoinedUser || 0}</h1>
              </div>
              <div>
                <img src={images.usermonth} style={{ width: '84px', Height: '150px' }} /></div>
            </div>
            <div className="user-text">
              <p>Total registered users in last month</p>
            </div>

          </div>

          <div className="datacenter user-valid-text">
            <div className="image-fle">
              <div className="total-num">
              <h1>{dashboardData?.lastThreeMonthJoinedUser || 0}</h1>
              </div>
              <div>
                <img src={images.userquarter} style={{ width: '84px', Height: '150px' }} /></div>
            </div>
            <div className="user-text">
              <p>Total users registered in last 3 months</p>
            </div>

          </div>

          <div className="datacenter user-valid-text">
            <div className="image-fle">
              <div className="total-num">
              <h1>{dashboardData?.lastSixMonthJoinedUser || 0}</h1>
              </div>
              <div>
                <img src={images.lastsix} style={{ width: '84px', Height: '150px' }} /></div>
            </div>
            <div className="user-text">
              <p>Total users registered in last 6 months  </p>
            </div>
          </div>

          <div className="datacenter user-valid-text">
            <div className="image-fle">
              <div className="total-num">
              <h1>{dashboardData?.totalUser || 0}</h1>
              </div>
              <div>
                <img src={images.thisyear} style={{ width: '84px', Height: '150px' }} /></div>
            </div>
            <div className="user-text">
              <p>Total users registered in last year  </p>
            </div>
          </div>
        </div>

        <div className="dash-board-heading-text">
          <p>Total Number Of Users Subscribed</p>
        </div>
        <div className="main-div-2 dashboard ">


        

          <div className="datacenter user-valid-text">
            <div className="image-fle">
              <div className="total-num">
              <h1>{dashboardData?.todayJoinedUser || 0}</h1>
              </div>
              <div>
                <img src={images.totalGame} style={{ width: '84px', Height: '150px' }} /></div>
            </div>
            <div className="user-text">
              <p>Total number of users subscribed today</p>
            </div>
          </div>




          <div className="datacenter user-valid-text">
            <div className="image-fle">
              <div className="total-num">
              <h1>{dashboardData?.lastWeekJoinedUser || 0}</h1>
              </div>
              <div>
                <img src={images.lastWeek} style={{ width: '84px', Height: '150px' }} /></div>
            </div>
            <div className="user-text">
              <p> Total number of users subscribed in last week </p>
            </div>
          </div>



          <div className="datacenter user-valid-text">
            <div className="image-fle">
              <div className="total-num">
              <h1>{dashboardData?.lastMonthJoinedUser || 0}</h1>
              </div>
              <div>
                <img src={images.lastMonth} style={{ width: '84px', Height: '150px' }} /></div>
            </div>
            <div className="user-text">
              <p>Total number of users subscribed in last month
</p>
            </div>

          </div>

          <div className="datacenter user-valid-text">
            <div className="image-fle">
              <div className="total-num">
              <h1>{dashboardData?.lastThreeMonthJoinedUser || 0}</h1>
              </div>
              <div>
                <img src={images.lastThree} style={{ width: '84px', Height: '150px' }} /></div>
            </div>
            <div className="user-text">
              <p>Total number of users subscribed in last 3 months</p>
            </div>

          </div>

          <div className="datacenter user-valid-text">
            <div className="image-fle">
              <div className="total-num">
              <h1>{dashboardData?.lastSixMonthJoinedUser || 0}</h1>
              </div>
              <div>
                <img src={images.lastSixx} style={{ width: '84px', Height: '150px' }} /></div>
            </div>
            <div className="user-text">
            <p>Total number of users subscribed in last 6 months</p>
            </div>

          </div>

          <div className="datacenter user-valid-text">
            <div className="image-fle">
              <div className="total-num">
              <h1>{dashboardData?.totalUser || 0}</h1>
              </div>
              <div>
                <img src={images.lastYear} style={{ width: '84px', Height: '150px' }} /></div>
            </div>
            <div className="user-text">
              <p>Total number of users subscribed in last year</p>
            </div>
          </div>



        </div>

      </div>



    </div>



  )

}
export default Dashboard