import React, { useState, useEffect } from "react"
import { Image, Layout, Menu } from 'antd';
import {
    BrowserRouter as Router,
    Link,
    useLocation
} from "react-router-dom";
import appconstant from "../../themes/appconstant";
import { FontAwesomeIcon, fadashcube } from '@fortawesome/react-fontawesome'
import { faHome, faUser, faNewspaper, faBell, faShoppingCart, faUsers } from "@fortawesome/free-solid-svg-icons";
import { Table, Breadcrumb } from 'antd';
import Textfiled from "../../common";
import images from "../../themes/appImage";
import { useDispatch, useSelector } from "react-redux";
import { viewUserAction } from "../../redux/Action/Auth";
import { reduxConstant } from "../../constants/appconstant";


const { Header, Content, Footer, Sider } = Layout;

const Reportdetails = () => {
    const location = useLocation();
    const dispatch = useDispatch();
    const userDetail = useSelector(
        (state) => state.userManagementReducer?.userDetail
      );

    console.log(location,"this is my location");
    const [isVisible, setVisible] = useState(false);
    const handlewClick = () => {
        setVisible(!isVisible)
    }
    useEffect(() => {
        document.title = "YezidiLink";
        window.scrollTo(0, 0);
    
        const data = {
          user: location?.state?.reportid, // Use reportid here
          reportId: location?.state?.listid // Use listid here
        };
        dispatch(viewUserAction(data));
      }, [dispatch, location]);







      return (
        <div>
          <Breadcrumb>
            <Breadcrumb.Item><Link to="/dashboard"><FontAwesomeIcon icon={faHome} /></Link></Breadcrumb.Item>
            <Breadcrumb.Item><Link to="/reportmanagement">{appconstant.management}</Link></Breadcrumb.Item>
            <Breadcrumb.Item>{appconstant.reportuser}</Breadcrumb.Item>
          </Breadcrumb>
          <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
            <div className="content-e">
              <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 system-line">
                <h6 className="text-white text-capitalize ps-3">{appconstant.reportuser}</h6>
              </div>
              <div className="datacenter">
              <Image src ={`${reduxConstant.IMAGE_URL}/${userDetail?.image}`} style={{ width: "100px", Height: "200px" }} />
              </div>
              <div className="wrapper_line">
                <div className="fields">
                  <label>{appconstant.userName}</label>
                  <p>{userDetail?.nickName || "N/A"}</p>
                </div>
                <div className="fields">
                  <label>{appconstant.firstname}</label>
                  <p>{userDetail?.firstName || "N/A"}</p>
                </div>
                <div className="fields">
                  <label>{appconstant.lastname}</label>
                  <p>{userDetail?.lastName || "N/A"}</p>
                </div>
                <div className="fields">
                  <label>{appconstant.gender}</label>
                  <p>{userDetail?.gender || "N/A"}</p>
                </div>
                <div className="fields">
                  <label>{appconstant.dateOfbirth}</label>
                  <p>{userDetail?.age ? `${userDetail.age} years` : "N/A"}</p>
                </div>
                <div className="fields">
                  <label>{appconstant.EmailAddress}</label>
                  <p>{userDetail?.email || "N/A"}</p>
                </div>
                <div className="fields">
                  <label>{appconstant.phone}</label>
                  <p>{userDetail?.phone || "N/A"}</p>
                </div>
                <div className="fields">
                  <label>{appconstant.Reason}</label>
                  <p className="txt">{userDetail?.reportReason || "N/A"}</p>
                </div>
              </div>
              <div className="btns-app">
                <div className="btns-image">
                  <Link className="btns-link" to="/reportmanagement"><div className="btn-txt">{appconstant.submit}</div></Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    };
    
    export default Reportdetails;