import React from "react";
import {  Breadcrumb, Input } from 'antd'; // Import Input from antd
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { changePassword } from '../../redux/Action/Auth';
import appconstant from "../../themes/appconstant";



const Changepassword = () => {
    const dispatch = useDispatch();

    const formik = useFormik({
        initialValues: {
            oldPassword: "",
            newPassword: "",
            confirmPassword: ""
        },
        validationSchema: Yup.object().shape({
            oldPassword: Yup.string()
                .trim()
                .required('Please enter old password.'),
            newPassword: Yup.string()
                .trim()
                .required('Please enter new password.')
                .min(8, 'New password must include 8 characters, 1 upper case letter, 1 lower case letter, 1 numeric value, 1 special character and no spaces.')
                .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*()\-_=+{};:,<.>/?]).+$/, "New password must include 8 characters, 1 upper case letter, 1 lower case letter, 1 numeric value, 1 special character and no spaces."),
            confirmPassword: Yup.string()
                .trim()
                .required('Please enter confirm new password.')
                .oneOf([Yup.ref("newPassword"), null], 'New password and confirm new password must be same.')
        }),
        onSubmit: (values) => {
            const trimmedValues = {
                oldPassword: values.oldPassword.trim(),
                newPassword: values.newPassword.trim(),
                confirmPassword: values.confirmPassword.trim()
            };
            dispatch(changePassword(trimmedValues));
        }
    });

    return (
        <div>
            <Breadcrumb>
                <Breadcrumb.Item><Link to="/dashboard"><FontAwesomeIcon icon={faHome} /></Link></Breadcrumb.Item>
                <Breadcrumb.Item><Link to="/settings">{appconstant.settings}</Link></Breadcrumb.Item>
                <Breadcrumb.Item>{appconstant.changepassword}</Breadcrumb.Item>
            </Breadcrumb>
            <div className="site-layout-background" style={{ padding: 24 }}>
                <div className="content-e butoo-pa">
                    <div class="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3">
                        <h6 class="text-white text-capitalize ps-3">{appconstant.changepassword}</h6>
                    </div>

                    <div class="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3">
                        <h6 class="text-white text-capitalize ps-3 mt-title">{appconstant.Oldpassword}</h6>
                    </div>
                    <div className="key">
                        <Input.Password
                            className='form-control input-with-icon'
                            placeholder='Old Password'
                            name='oldPassword'
                            value={formik.values.oldPassword.trim()}
                            onChange={formik.handleChange}
                        />
                        {formik.errors.oldPassword && formik.touched.oldPassword && (
                            <div style={{ color: "red" }}>{formik.errors.oldPassword}</div>
                        )}
                    </div>

                    <div class="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3">
                        <h6 class="text-white text-capitalize ps-3 mt-title">{appconstant.NewPassword}</h6>
                    </div>
                    <div className="key">
                        <Input.Password
                            className='form-control input-with-icon'
                            placeholder='New Password'
                            name='newPassword'
                            value={formik.values.newPassword.trim()}
                            onChange={formik.handleChange}
                        />
                        {formik.errors.newPassword && formik.touched.newPassword && (
                            <div style={{ color: "red" }}>{formik.errors.newPassword}</div>
                        )}
                    </div>

                    <div class="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3">
                        <h6 class="text-white text-capitalize ps-3 mt-title">{appconstant.ConfirmNewPassword}</h6>
                    </div>
                    <div className="key">
                        <Input.Password
                            className='form-control'
                            placeholder='Confirm New Password'
                            name='confirmPassword'
                            value={formik.values.confirmPassword.trim()}
                            onChange={formik.handleChange}
                        />
                        {formik.errors.confirmPassword && formik.touched.confirmPassword && (
                            <div style={{ color: "red" }}>{formik.errors.confirmPassword}</div>
                        )}
                    </div>

                    <button className="btns-app" onClick={formik.handleSubmit} style={{ cursor: "pointer" }}>
                        <div className="btns-image">
                            <div className="btns-link">
                                <div style={{ color: 'white' }} className="btn-txt">{appconstant.update}</div>
                            </div>
                        </div>
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Changepassword;
