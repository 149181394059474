import { all, fork } from "redux-saga/effects";
import rootAuthSaga from "./Auth";
import UserManagementSaga from "./userManagementSaga";
import GameManagementSaga from "./gameManagmentSaga";
import contactManagementSaga from "./contactManagmentSaga";
import deleteManagementSaga from "./deleteManagementSaga";
import globalManagementSaga from "./globalManagementSaga";

export default function* rootSaga() {
  yield all([
    fork(rootAuthSaga),
    fork(UserManagementSaga),
    fork(GameManagementSaga),
    fork(contactManagementSaga),
    fork(deleteManagementSaga),
    fork(globalManagementSaga)
  ]);
}
