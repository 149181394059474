import { put, call, takeLatest } from "@redux-saga/core/effects";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { navigate } from "../../utils/navigator";
import { msgConstant, reduxConstant } from "../../constants/appconstant";
import {  getDeleteListApi, getDeleteUserApi } from "../Api";


function* getDeleteListSaga(action) {
  try {
  
    const response = yield call(getDeleteListApi, action.data);


    if (response?.status == 0 ) {
      yield put({
        type: reduxConstant.API_DELETE_LIST_SUCCESS,
        result: response.result,
      });
      toast.success(response.result.message);

    } else {
      toast.error(response.result.message);
      yield put({ type: reduxConstant.API_DELETE_LIST_FAILED });
    }
  } catch (error) {
    toast.error(msgConstant.messageStatus500);
    yield put({ type: reduxConstant.API_DELETE_LIST_FAILED });
  }
}



function* getDeleteUserSaga(action) {
  try {
  
    const response = yield call(getDeleteUserApi, action.data);


    if (response?.status == 0 ) {
      yield put({
        type: reduxConstant.API_DELETE_USER_SUCCESS,
        result: response.result,
      });
      toast.success(response.result.message);

    } else {
      toast.error(response.result.message);
      yield put({ type: reduxConstant.API_DELETE_USER_FAIL });
    }
  } catch (error) {
    toast.error(msgConstant.messageStatus500);
    yield put({ type: reduxConstant.API_DELETE_USER_FAIL });
  }
}

export default function* deleteManagementSaga() {
  yield takeLatest(reduxConstant.API_DELETE_LIST_LOAD, getDeleteListSaga);
  yield takeLatest(reduxConstant.API_DELETE_USER_LOAD, getDeleteUserSaga);

}
