import React, { useEffect } from "react";
import { Layout, Breadcrumb } from "antd";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import appconstant from "../../themes/appconstant";
import { useDispatch } from "react-redux";
import { createAnnoucmentAction } from "../../redux/Action/Auth";

const { Header, Content, Footer, Sider } = Layout;

const Createmessage = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    document.title = "YezidiLink";
    window.scrollTo(0, 0);
  }, []);

  const initialValues = {
    heading: "",
    details: "",
  };

  const validationSchema = Yup.object({
    heading: Yup.string().required("Heading is required"),
    details: Yup.string().required("Details are required"),
  });

  const handleSubmit = (values, { setSubmitting }) => {
    dispatch(createAnnoucmentAction(values));
    setSubmitting(false);
  };

  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/dashboard">
            <FontAwesomeIcon icon={faHome} />
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to="/globalmanagement"> {appconstant.globalmanage} </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{appconstant.createmessage}</Breadcrumb.Item>
      </Breadcrumb>
      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <div className="content-e">
          <div className="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 system-line">
            <h6 className="text-white text-capitalize ps-3">
              {appconstant.createmessage}
            </h6>
          </div>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ isSubmitting, handleChange, values, errors, touched }) => (
              <Form>
                <div className="wrapper_line">
                  <div className="form-group">
                    <label>{appconstant.heading}</label>
                    <input
                      type="text"
                      name="heading"
                      className={`form-control ${
                        errors.heading && touched.heading ? "is-invalid" : ""
                      }`}
                      placeholder="Heading"
                      value={values.heading}
                      onChange={handleChange}
                    />
                    <ErrorMessage
                      name="heading"
                      component="div"
                      className="invalid-feedback"
                      style = {{color:"red"}}
                    
                    />
                  </div>
                  <div className="form-group" style={{ height: "300px" }}>
                    <label>{appconstant.details}</label>
                    <textarea
                      name="details"
                      className={`form-controld ${
                        errors.details && touched.details ? "is-invalid" : ""
                      }`}
                      style={{
                        width: "100%",
                        maxWidth: "100%",
                        overflowX: "hidden",
                        resize: "none",
                      }}
                      placeholder="Details"
                      value={values.details}
                      onChange={handleChange}
                    />
                    <ErrorMessage
                      name="details"
                      component="div"
                      className="invalid-feedback"
                      style = {{color:"red"}}
                    />
                  </div>
                  <button className="btns-app" type="submit">
                    <div className="btns-image">
                      <div className="btns-link" to="/globalmanagement">
                        <div className="btn-txt">{appconstant.send}</div>{" "}
                      </div>
                    </div>
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </div>
  );
};

export default Createmessage;
