import { reduxConstant } from "../../constants/appconstant";

const initialState = {
    isLoading: false,
    contactList: [],
    pagination: {},
}

export const contactManagementReducer = (state = initialState, action) => {

    switch (action.type){
        case reduxConstant.API_CONTACT_LIST_LOAD :
            return {
                ...state,
                isLoading : true,
               
            }
        case reduxConstant.API_CONTACT_LIST_SUCCESS : 
            return {
                ...state,
                contactList : action?.result?.data,
                isLoading:false,
               
            }
        case reduxConstant.API_CONTACT_LIST_FAILED : 
            return {
                ...state,
                isLoading : false
            }
        default : return {...state}

    }
}