import { reduxConstant } from "../../constants/appconstant"

const intialState = {
    isLoading: false,
    dashboard: null,
    linkFetched: false
}

export const authReducer = (state = intialState, action) => {

    switch (action.type) {
        //login
        case reduxConstant.LOGIN_LOAD:
            return {
                ...state,
                isLoading: true,
            };
        case reduxConstant.LOGIN_SUCCESS:
            return {
                ...state,
                isLoading: false,
            };
        case reduxConstant.LOGIN_FAIL:
            console.log("Called");
            return {
                ...state,
                isLoading: false,
            };

        //forgot
        case reduxConstant.FORGOT_LOAD:
            return {
                ...state,
                isLoading: true,
            };
        case reduxConstant.FORGOT_SUCCESS:
            return {
                ...state,
                isLoading: false,
                linkFetched: true
            };
        case reduxConstant.FORGOT_FAIL:
            return {
                ...state,
                isLoading: false,
            };
        //dashboard
        case reduxConstant.DASHBOARD_LOAD:
            return {
                ...state,
                isLoading: true,
            };
        case reduxConstant.DASHBOARD_SUCCESS:
            return {
                ...state,
                isLoading: false,
                dashboard: action?.payload
            };
        case reduxConstant.DASHBOARD_FAILURE:
            return {
                ...state,
                isLoading: false,
            };

        //logout

        case reduxConstant.LOGOUT_LOAD:
            return {
                ...state,
                isLoading: true,
            };
        case reduxConstant.LOGOUT_SUCCESS:
            return {
                ...state,
                isLoading: false,
            };
        case reduxConstant.LOGOUT_FAIL:
            return {
                ...state,
                isLoading: false,
            };


        //change password

        case reduxConstant.CHANGE_PASSWORD_LOAD:
            return {
                ...state,
                isLoading: true,
            };
        case reduxConstant.CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                isLoading: false,
            };
        case reduxConstant.CHANGE_PASSWORD_FAIL:
            return {
                ...state,
                isLoading: false,
            };

        //link valid
        case reduxConstant.FORGOT_LINK_LOAD:
            return {
                ...state,
                isLoading: true,
            };
        case reduxConstant.FORGOT_LINK_SUCCESS:
            return {
                ...state,
                isLoading: false,
                linkFetched: true
            };
        case reduxConstant.FORGOT_LINK_FAIL:
            return {
                ...state,
                isLoading: false,
            };


        // reset password
        case reduxConstant.RESET_PASSWORD_LOAD:
            return {
                ...state,
                isLoading: true,
            };
        case reduxConstant.RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                isLoading: false,
            };
        case reduxConstant.RESET_PASSWORD_FAIL:
            return {
                ...state,
                isLoading: false,
            };



        default:
            return { ...state }

    }



}