import { reduxConstant } from "../../constants/appconstant";

const initialState = {
  isLoading: false,
  userList: [],
  pagination: {},
  userDetail: {},
  reportList : [],
  isBlockSuccess : null,
  isDeleteSuccess : null,
};

export const userManagementReducer = (state = initialState, action) => {
  switch (action.type) {
    //user list
    case reduxConstant.API_User_List_LOAD:
      return { ...state, isLoading: true };
    case reduxConstant.API_USER_LIST_SUCCESS: {
      console.log("this is my action", action?.result?.data);
      return {
        ...state,
        isLoading: false,
        userList: action?.result?.data.list,
        pagination: action?.result?.data?.pagination,
      };
    }

    case reduxConstant.API_EDIT_USER_LOAD : 
    return {...state, isLoading : true};

    case reduxConstant.API_EDIT_USER_SUCCESS :
    return {...state , isLoading : false};


    case reduxConstant.API_BLOCK_USER_LOAD:
      return { ...state, isLoading: true , isBlockSuccess : false };

    case reduxConstant.API_BLOCK_USER_SUCCESS:
      // let data = state.userList;
      // let index = data.findIndex((x) => x._id === action.result.user);
      // data[index].isBlock = !data[index].isBlock;
      // state.userList = data;
      return {
        ...state,
        isLoading: false,
        isBlockSuccess : true

      };

    case reduxConstant.API_VIEW_USER_LOAD:
      return { ...state, isLoading: true, userDetail: {} };
    case reduxConstant.API_VIEW_USER_SUCCESS:
      return { ...state, isLoading: false, userDetail: action?.result?.data };

    //Fail
    case reduxConstant.API_USER_MANAGEMENT_FAIL:
      return { ...state, isLoading: false };

    case reduxConstant.API_REPORT_LIST_LOAD : 
    return { ...state, isLoading:true }

    case reduxConstant.API_REPORT_LIST_SUCCESS : 
    console.log(action.result.data, "this is my ACTION RESULT")
    return {...state , isLoading :false , reportList : action?.result?.data}

    case reduxConstant.API_REPORT_LIST_FAILED : 
    return {...state , isLoading : false }

    case reduxConstant.ID_VERIFICATION_LOAD : 
    return {
      ...state , isLoading :true ,
    
    }
    
    case reduxConstant.ID_VERIFICATION_SUCCESS : 
    return {
      ...state , isLoading : false,
     
    }

    case reduxConstant.REPORT_VIEW_LOAD : 
    return {
      ...state,
      isDeleteSuccess : false
    }
    case reduxConstant.REPORT_VIEW_SUCCESS : 
    return {
      ...state,
      isDeleteSuccess : true
    }

    //default
    default:
      return { ...state };
  }
};
