import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Link, useNavigate } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faHome} from "@fortawesome/free-solid-svg-icons";
import { Breadcrumb, Modal } from "antd";
import appconstant from "../../themes/appconstant";
import { logout } from "../../redux/Action/Auth"
import { useDispatch } from "react-redux";



const Settings = () => {
  const dispatch = useDispatch();
  const [isModalOpen, setIsModalOpen] = useState(false);
  useEffect(() => {
    return () =>    Modal.destroyAll()
  }, [])

  const handleOk = () => {
    dispatch(logout());
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const deleteModel = (record) => {
    Modal.confirm({
        title: "Logout",
        content: <div className="coins-input ">
          <p style={{marginTop:"20px"}}> Are you sure, you want to logout?</p>
        </div>,
        okText: 'Yes ',
        centered: true,
        cancelText: 'No ',
        onOk() {
          handleOk()
        },
        onCancel() {
          handleCancel()
        },
        maskClosable: true,
        className: "new-button",
     
    });
};

  return (
    <div>
      <Breadcrumb>
        <Breadcrumb.Item>
          <Link to="/dashboard">
            <FontAwesomeIcon icon={faHome} />
          </Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>{appconstant.settings}</Breadcrumb.Item>
      </Breadcrumb>

      <div
        className="site-layout-background"
        style={{ padding: 24, minHeight: 360 }}
      >
        <div className="content-e" id="setting-btn">
          <div className="setting-btns">
            <Link to="/changepassword">
              <button type="submit" className="button-btn ">
                {appconstant.changepassword}
              </button>
            </Link>
          </div>

          <div className="setting-btn" onClick={() => deleteModel()}>
            <button className="button-btn ">{appconstant.logout}</button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Settings;
