import React, { useState, useEffect } from "react"
import { Layout, Menu } from 'antd';
import {
    BrowserRouter as Router,
    Link
} from "react-router-dom";
import appconstant from "../../themes/appconstant";
import { FontAwesomeIcon, fadashcube } from '@fortawesome/react-fontawesome'
import { faHome, faUser, faNewspaper, faBell, faShoppingCart, faUsers } from "@fortawesome/free-solid-svg-icons";
import { Table, Breadcrumb } from 'antd';
import Textfiled from "../../common";
import images from "../../themes/appImage";


const { Header, Content, Footer, Sider } = Layout;

const Contactdetails = () => {
    const [isVisible, setVisible] = useState(false)
    const handlewClick = () => {
        setVisible(!isVisible)
    }
    useEffect(() => {
        document.title = 'YezidiLink';
        window.scrollTo(0, 0)
    }, [])







    return (

        <div>
            <Breadcrumb>
                <Breadcrumb.Item><Link to="/dashboard"><FontAwesomeIcon icon={faHome} /></Link></Breadcrumb.Item>
                <Breadcrumb.Item><Link to="/contact"> {appconstant.contactus} </Link></Breadcrumb.Item>
                <Breadcrumb.Item>{appconstant.contactD}</Breadcrumb.Item>
            </Breadcrumb>
            <div className="site-layout-background" style={{ padding: 24, minHeight: 360 }}>
                <div className="content-e">
                    <div class="bg-gradient-primary shadow-primary border-radius-lg pt-4 pb-3 system-line">
                        <h6 class="text-white text-capitalize ps-3">{appconstant.contactD}</h6>
                     

                    </div>

                    <div className="datacenter">
                        <img src={images.usermessage} style={{ width: '100px', Height: '200px' }} />


                    </div>
                    <div className="wrapper_line">

                        <div className="fields">
                            <label>{appconstant.firstName}</label>
                            <p>{appconstant.John}</p>
                        </div>
                        <div className="fields">
                            <label>{appconstant.lastname}</label>
                            <p>{appconstant.JohnDee}</p>
                        </div>
                        <div className="fields">
                            <label>{appconstant.nickName}</label>
                            <p>{appconstant.nickName}</p>
                        </div>

                        < div className="fields">
                            <label>{appconstant.gender}</label>
                            <p>{appconstant.genderdata}</p>
                        </div>
                        < div className="fields">
                            <label>{appconstant.dateOfbirth}</label>
                            <p>{appconstant.birthdata}</p>
                        </div>
                        < div className="fields">
                            <label>{appconstant.EmailAddress}</label>
                            <p>{appconstant.id}</p>
                        </div>
                        < div className="fields">
                            <label>{appconstant.phone}</label>
                            <p>{appconstant.number}</p>
                        </div>
                        < div className="fields">
                            <label>{appconstant.message}</label>
                            <p className="txt">{appconstant.textD}</p>
                        </div>
                      
             


                  
                    </div>
               
                            <div className="btns-app">
                            <div className="btns-image">
                         
                            <Link className="btns-link" to="/contact" ><div className="btn-txt">{appconstant.submit}</div></Link>
                           
                            </div></div>
                        </div>
                      
                        </div>
                   
                    
                        </div>
    

    )
}
export default Contactdetails;
