import React, { useState, Component } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";

import Dashboard from "../components/dashboard";
import ForgotPassword from "../components/forgotpassword";
import Login from "../components/login";
import MainLayout from "../layout/mainlayout";
import UserManagement from "../components/usermanagement";
import GlobalManagement from "../components/globalmangement";
import UsesrDetail from "../components/usermanagement/viewUser";
import EditUserDetail from "../components/usermanagement/editUserDetail";
import Createmessage from "../components/globalmangement/creatmessage";
import ReportManagement from "../components/reportmanagement";
import Reportdetails from "../components/reportmanagement/reportdetails";
import Userverification from "../components/usermanagement/userverification";
import Verifyuser from "../components/usermanagement/verifiuser";
import Deleteuser from "../components/deleteuser";
import Deletedetails from "../components/deleteuser/deleteuserdetail";
import Changepassword from "../components/changepassword";
import Settings from "../components/settings";
import Contactus from "../components/contact";
import Contactdetails from "../components/contact/contactdetails";
import ResetPassword from "../components/resetpassword/ResetPassword";
import PrivateRoute, { PublicRoute } from "./privateRoute";
import UserSigned from "../components/usermanagement/userSignUp";

const RoutesNew = () => {
  return (
    <div>
      <Routes>
        <Route
          path="/"
          element={
            <Navigate to={!localStorage.token ? "login" : "dashboard"} />
          }
        />

        <Route
          path="/login"
          element={
            <PublicRoute>
              <Login />
            </PublicRoute>
          }
        />
        <Route
          path="/resetPassword"
          element={
            <PublicRoute>
              <ResetPassword />
            </PublicRoute>
          }
        />
        <Route
          path="/forgotpassword"
          element={
            <PublicRoute>
              <ForgotPassword />
            </PublicRoute>
          }
        />
        <Route path="/" element={<MainLayout />}>
          <Route
            path="/contactdetails"
            element={
              <PrivateRoute>
                <Contactdetails />
              </PrivateRoute>
            }
          />
          <Route
            path="/dashboard"
            element={
              <PrivateRoute>
                <Dashboard />
              </PrivateRoute>
            }
          />
          <Route
            path="/deleteuser"
            element={
              <PrivateRoute>
                <Deleteuser />
              </PrivateRoute>
            }
          />
          <Route
            path="/contact"
            element={
              <PrivateRoute>
                {" "}
                <Contactus />
              </PrivateRoute>
            }
          />

          <Route
            path="/usermanagement"
            element={
              <PrivateRoute>
                <UserManagement />
              </PrivateRoute>
            }
          />
          <Route
            path="/deleteuserdetails"
            element={
              <PrivateRoute>
                <Deletedetails />
              </PrivateRoute>
            }
          />
          <Route
            path="/changepassword"
            element={
              <PrivateRoute>
                <Changepassword />
              </PrivateRoute>
            }
          />
          <Route
            path="/verifyuser"
            element={
              <PrivateRoute>
                <Verifyuser />
              </PrivateRoute>
            }
          />
          <Route
            path="/signUpUser"
            element={
              <PrivateRoute>
                <Verifyuser />
              </PrivateRoute>
            }
          />
          <Route
            path="/globalmanagement"
            element={
              <PrivateRoute>
                <GlobalManagement />
              </PrivateRoute>
            }
          />
          <Route
            path="/usesrdetail"
            element={
              <PrivateRoute>
                <UsesrDetail />
              </PrivateRoute>
            }
          />
          <Route
            path="/edituserdetail"
            element={
              <PrivateRoute>
                <EditUserDetail />
              </PrivateRoute>
            }
          />
          <Route
            path="/userverification"
            element={
              <PrivateRoute>
                <Userverification />
              </PrivateRoute>
            }
          />

          <Route
            path="/usersigned"
            element={
              <PrivateRoute>
                <UserSigned />
              </PrivateRoute>
            }
          />

          <Route
            path="/createmessage"
            element={
              <PrivateRoute>
                <Createmessage />
              </PrivateRoute>
            }
          />
          <Route
            path="/reportmanagement"
            element={
              <PrivateRoute>
                {" "}
                <ReportManagement />
              </PrivateRoute>
            }
          />
          <Route
            path="/reportdetails"
            element={
              <PrivateRoute>
                <Reportdetails />
              </PrivateRoute>
            }
          />
          <Route
            path="settings"
            element={
              <PrivateRoute>
                <Settings />
              </PrivateRoute>
            }
          />
        </Route>
      </Routes>
    </div>
  );
};
export default RoutesNew;
